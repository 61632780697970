import type { FunctionConfig } from "utils/functionsTypes";

export const depositQueueDepositFromQueue: FunctionConfig = {
  signature: "function depositFromQueue(uint88 _endId, uint256[] calldata _idsToBypass)",

  Description() {
    return <>Execute deposit requests</>;
  },

  Label() {
    return <>Execute Deposit Requests</>;
  },
};
