import { useNetwork } from "components/providers/NetworkProvider";
import type { FunctionConfig } from "utils/functionsTypes";
import { isAddressEqual } from "viem";

export const dispatcherOwnedBeaconFactoryDeployProxy: FunctionConfig = {
  signature: "function deployProxy(bytes calldata _constructData)",
  Description({ address }) {
    const { environment } = useNetwork();

    if (isAddressEqual(address, environment.contracts.SingleAssetDepositQueueFactory)) {
      return <>Create Single Asset Deposit Queue</>;
    }

    return <>Create Proxy</>;
  },

  Label({ address }) {
    const { environment } = useNetwork();

    if (isAddressEqual(address, environment.contracts.SingleAssetDepositQueueFactory)) {
      return <>Create Single Asset Deposit Queue</>;
    }

    return <>Create Proxy</>;
  },
};
