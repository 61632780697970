import { toAddress } from "@enzymefinance/environment";
import { Badge, Divider, PageHeading, Tooltip, TooltipProvider } from "@enzymefinance/ui";
import { useSigner } from "components/connection/Connection";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { VaultUnknownFeeWarning } from "components/vault/heading/VaultUnknownFeeWarning";
import { useVaultProfileQuery } from "queries/backend";
import { useMemo } from "react";
import { client as backendClient } from "utils/backend";
import { useExecutableMigration } from "utils/hooks/useExecutableMigration";
import { useSharesWrapperRedemptionInfo } from "utils/hooks/useSharesWrapperRedemptionInfo";
import { useSignaledMigration } from "utils/hooks/useSignaledMigration";
import { useSignaledReconfiguration } from "utils/hooks/useSignaledReconfiguration";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { isAddressEqual, zeroAddress } from "viem";

import { useSharesBalance } from "../../../utils/hooks/useSharesBalance";
import { useSingleAssetRedemptionQueueInfo } from "../../../utils/hooks/useSingleAssetRedemptionQueueInfo";
import { VaultIcon } from "../VaultIcon";
import { useVault } from "../VaultProvider";
import { BypassableCumulativeSlippagePolicyWarning } from "./VaultBypassableCumulativeSlippagePolicyWarning";
import { VaultDepositButton } from "./VaultDepositButton";
import { VaultDepositedBadge } from "./VaultDepositedBadge";
import { VaultReconfigurationPending } from "./VaultReconfigurationPending";
import { VaultRedeemButton } from "./VaultRedeemButton";
import { RemovedExternalPositionWarning } from "./VaultRemovedExternalPositionWarning";
import { UnclaimedFeesWarning } from "./VaultUnclaimedFeesWarning";
import { VaultUnmigratedWarning } from "./VaultUnmigratedWarning";
import { UntrackedAssetsWarning } from "./VaultUntrackedAssetsWarning";
import { VaultUpgradePending } from "./VaultUpgradePending";

export function VaultPageHeading() {
  const [signerAddress] = useSigner();
  const { comptrollerProxy, id, isOwner, vault } = useVault();
  const { client, deployment } = useNetwork();
  const { currentReleaseId, contracts } = useGlobals();

  const sharesWrapperQuery = useSharesWrapperRedemptionInfo({
    vaultId: id,
    comptroller: toAddress(vault.comptroller.id),
    account: signerAddress ?? zeroAddress,
  });

  const sharesWrapperInfo = useMemo(() => sharesWrapperQuery?.data ?? null, [sharesWrapperQuery]);
  const sharesWrapperInfoLoading = Boolean(sharesWrapperQuery?.loading);

  const redemptionQueueQuery = useSingleAssetRedemptionQueueInfo({
    vaultId: toAddress(vault.id),
    comptroller: toAddress(comptrollerProxy),
    account: signerAddress ?? zeroAddress,
  });

  const redemptionQueueInfo = useMemo(() => redemptionQueueQuery?.data ?? null, [redemptionQueueQuery]);
  const redemptionQueueInfoLoading = Boolean(redemptionQueueQuery?.loading);

  // Shares balance query
  const sharesBalanceQuery = useSharesBalance(client, {
    account: signerAddress,
    vault: id,
  });

  const wrappedSharesBalanceQuery = useTokenBalance(client, {
    account: signerAddress,
    token:
      sharesWrapperInfoLoading || sharesWrapperInfo?.sharesWrapperAddress === undefined
        ? undefined
        : sharesWrapperInfo.sharesWrapperAddress,
  });

  const signaledMigrationQuery = useSignaledMigration(client, { dispatcher: contracts.Dispatcher, vaultProxy: id });
  const executableMigrationQuery = useExecutableMigration(client, {
    dispatcher: contracts.Dispatcher,
    vaultProxy: id,
  });

  const signaledReconfigurationQuery = useSignaledReconfiguration(client, {
    fundDeployer: contracts.FundDeployer,
    release: toAddress(vault.release.id),
    vaultProxy: toAddress(id),
  });

  const vaultProfileQuery = useVaultProfileQuery({
    client: backendClient,
    variables: { vaultAddress: id },
  });

  const upgradePending = signaledMigrationQuery.data;
  const upgradeExecutable = executableMigrationQuery.data;
  const reconfigurationPending = !!signaledReconfigurationQuery.data;

  const upgradeable = !isAddressEqual(toAddress(currentReleaseId), toAddress(vault.release.id));

  const isDeposited = ((sharesBalanceQuery.data || wrappedSharesBalanceQuery.data) ?? 0n) > 0n;
  const hasRedemptionQueueRequest = !!redemptionQueueInfo?.requests;

  const tagline = vaultProfileQuery.data?.vaultProfile?.tagline;
  const vaultStrategies = vaultProfileQuery.data?.vaultProfile?.vaultStrategies;

  return (
    <div>
      <PageHeading
        actions={
          <div className="flex items-center space-x-2 md:py-[5px]">
            {signerAddress && !upgradeable ? (
              <>
                {isDeposited || hasRedemptionQueueRequest ? (
                  <VaultRedeemButton
                    comptrollerProxy={comptrollerProxy}
                    vault={vault}
                    signerAddress={signerAddress}
                    sharesWrapperRedemptionInfo={sharesWrapperInfo}
                    redemptionQueueRedemptionInfo={redemptionQueueInfo}
                    loading={sharesWrapperInfoLoading || redemptionQueueInfoLoading}
                  />
                ) : null}
                <VaultDepositButton vault={vault} signerAddress={signerAddress} />
              </>
            ) : null}
          </div>
        }
      >
        <div className="flex items-center space-x-2">
          <div className="flex-none self-start py-0.5">
            <VaultIcon aria-hidden={true} id={id} deployment={deployment} size={14} />
          </div>

          <div className="flex flex-col items-start md:flex-row md:flex-wrap">
            <div className="px-2">
              <h2 className="break-word text-heading-content line-clamp-2 text-2xl font-bold sm:text-3xl">
                {vault.name}
              </h2>
              {typeof tagline === "string" ? <p className="break-word">{tagline}</p> : null}
            </div>
            <div className="flex flex-col md:flex-row md:flex-wrap">
              <VaultUnmigratedWarning
                upgradeable={upgradeable}
                isOwner={isOwner}
                upgradePending={upgradePending === true}
                id={id}
              />
              <VaultUpgradePending
                id={id}
                isOwner={isOwner}
                upgradePending={upgradePending === true}
                upgradeExecutable={upgradeExecutable === true}
              />

              {reconfigurationPending ? <VaultReconfigurationPending id={id} isOwner={isOwner} vault={vault} /> : null}

              {isOwner ? <UnclaimedFeesWarning vaultId={id} /> : null}
              <VaultUnknownFeeWarning comptrollerId={toAddress(vault.comptroller.id)} />
              <RemovedExternalPositionWarning vaultId={id} />
              <UntrackedAssetsWarning vaultId={id} />
              <BypassableCumulativeSlippagePolicyWarning vaultId={id} />
              {signerAddress !== undefined ? <VaultDepositedBadge accountId={signerAddress} vaultId={id} /> : null}
            </div>
          </div>
        </div>
        {vaultStrategies?.length ? (
          <div>
            <div className="my-4 flex flex-wrap">
              {vaultStrategies.map((vaultStrategy) => (
                <TooltipProvider disabled={!vaultStrategy.description} key={vaultStrategy.id}>
                  <Tooltip.Item>
                    <div className="flex items-center p-1.5">
                      <Badge>{vaultStrategy.name}</Badge>
                    </div>
                  </Tooltip.Item>
                  <Tooltip.Panel>{vaultStrategy.description}</Tooltip.Panel>
                </TooltipProvider>
              ))}
            </div>
          </div>
        ) : null}
      </PageHeading>
      <Divider />
    </div>
  );
}
