import { Utils } from "@enzymefinance/sdk";
import { type CreateExternalPositionArgs, createExternalPositionDecode } from "@enzymefinance/sdk/Portfolio";
import { ExternalPositionType } from "@enzymefinance/utils";
import { useExternalPositionById } from "utils/hooks/useExternalPositionById";
import type { ExtensionHandler } from "../types";
import { createAaveV2ExternalPosition } from "./aaveV2";
import { createAaveV3ExternalPosition } from "./aaveV3";
import { createAliceExternalPosition } from "./alice";
import { createArbitraryLoanExternalPosition } from "./arbitraryLoan";
import { createCompoundV2ExternalPosition } from "./compoundV2";
import { createConvexVotingExternalPosition } from "./convexVoting";
import { createGMXV2ExternalPosition } from "./gmxV2";
import { createKilnExternalPosition } from "./kiln";
import { createLidoWithdrawalExternalPosition } from "./lidoWithdrawals";
import { createMapleExternalPosition } from "./maple";
import { createPendleV2ExternalPosition } from "./pendleV2";
import { createStaderWithdrawalExternalPosition } from "./staderWithdrawals";
import { createStakeWiseExternalPosition } from "./stakeWise";
import { createTheGraphDelegationExternalPosition } from "./theGraphDelegation";
import { createUniswapV3ExternalPosition } from "./uniswapV3";

export const createExternalPosition: ExtensionHandler<CreateExternalPositionArgs> = {
  Description({ args }) {
    const { typeId, callArgs } = args;
    const externalPosition = useExternalPositionById(typeId.toString());

    const callOnExternalPositionData = callArgs ?? "0x";

    const externalPositionType = externalPosition?.type;

    switch (externalPositionType) {
      case ExternalPositionType.MAPLE_LIQUIDITY:
        return <createMapleExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.UNISWAP_V3_LIQUIDITY:
        return <createUniswapV3ExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.AAVE_DEBT:
        return <createAaveV2ExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ZERO_LEND_LRT_BTC_AAVE_V3_DEBT:
      case ExternalPositionType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3_DEBT:
      case ExternalPositionType.AAVE_V3_DEBT: {
        const createExternalPosition = createAaveV3ExternalPosition(externalPositionType);

        return <createExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      }
      case ExternalPositionType.COMPOUND_DEBT:
        return <createCompoundV2ExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.CONVEX_VOTING:
        return (
          <createConvexVotingExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />
        );
      case ExternalPositionType.ARBITRARY_LOAN:
        return (
          <createArbitraryLoanExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />
        );
      case ExternalPositionType.THEGRAPH_DELEGATION:
        return (
          <createTheGraphDelegationExternalPosition.Description
            callOnExternalPositionData={callOnExternalPositionData}
          />
        );
      case ExternalPositionType.LIDO_WITHDRAWALS:
        return (
          <createLidoWithdrawalExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />
        );
      case ExternalPositionType.KILN_STAKING:
        return <createKilnExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.STADER_WITHDRAWALS:
        return (
          <createStaderWithdrawalExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />
        );
      case ExternalPositionType.STAKEWISE_V3:
        return <createStakeWiseExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.PENDLE_V2:
        return <createPendleV2ExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ALICE:
        return <createAliceExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.GMX_V2_LEVERAGE_TRADING:
        return <createGMXV2ExternalPosition.Description callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.UNKNOWN:
      case ExternalPositionType.NOTIONAL_V2:
      case ExternalPositionType.MORPHO_BLUE:
      case ExternalPositionType.LIQUITY_DEBT:
      case undefined:
        return <>Create Unknown External Position</>;
      default:
        Utils.Assertion.never(externalPositionType, `Unknown external position type: ${externalPositionType}`);
    }
  },
  Label({ args }) {
    const { typeId, callArgs } = args;

    const callOnExternalPositionData = callArgs ?? "0x";

    const externalPosition = useExternalPositionById(typeId.toString());

    const externalPositionType = externalPosition?.type;

    switch (externalPositionType) {
      case ExternalPositionType.MAPLE_LIQUIDITY:
        return <createMapleExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.UNISWAP_V3_LIQUIDITY:
        return <createUniswapV3ExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.AAVE_DEBT:
        return <createAaveV2ExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ZERO_LEND_LRT_BTC_AAVE_V3_DEBT:
      case ExternalPositionType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3_DEBT:
      case ExternalPositionType.AAVE_V3_DEBT: {
        const createExternalPosition = createAaveV3ExternalPosition(externalPositionType);

        return <createExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      }
      case ExternalPositionType.COMPOUND_DEBT:
        return <createCompoundV2ExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.CONVEX_VOTING:
        return <createConvexVotingExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ARBITRARY_LOAN:
        return <createArbitraryLoanExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.THEGRAPH_DELEGATION:
        return (
          <createTheGraphDelegationExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />
        );
      case ExternalPositionType.KILN_STAKING:
        return <createKilnExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.LIDO_WITHDRAWALS:
        return <createLidoWithdrawalExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.STADER_WITHDRAWALS:
        return <createStaderWithdrawalExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.STAKEWISE_V3:
        return <createStakeWiseExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.PENDLE_V2:
        return <createPendleV2ExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ALICE:
        return <createAliceExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.GMX_V2_LEVERAGE_TRADING:
        return <createGMXV2ExternalPosition.Label callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.UNKNOWN:
      case ExternalPositionType.NOTIONAL_V2:
      case ExternalPositionType.MORPHO_BLUE:
      case ExternalPositionType.LIQUITY_DEBT:
      case undefined:
        return <>Unknown External Position</>;
      default:
        Utils.Assertion.never(externalPositionType, `Unknown external position type: ${externalPositionType}`);
    }
  },
  Summary({ args }) {
    const { typeId, callArgs } = args;
    const externalPosition = useExternalPositionById(typeId.toString());

    const callOnExternalPositionData = callArgs ?? "0x";

    const externalPositionType = externalPosition?.type;

    switch (externalPositionType) {
      case ExternalPositionType.MAPLE_LIQUIDITY:
        return <createMapleExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.UNISWAP_V3_LIQUIDITY:
        return <createUniswapV3ExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.AAVE_DEBT:
        return <createAaveV2ExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ZERO_LEND_LRT_BTC_AAVE_V3_DEBT:
      case ExternalPositionType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3_DEBT:
      case ExternalPositionType.AAVE_V3_DEBT: {
        const createExternalPosition = createAaveV3ExternalPosition(externalPositionType);

        return <createExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      }
      case ExternalPositionType.COMPOUND_DEBT:
        return <createCompoundV2ExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.CONVEX_VOTING:
        return <createConvexVotingExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ARBITRARY_LOAN:
        return <createArbitraryLoanExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.THEGRAPH_DELEGATION:
        return (
          <createTheGraphDelegationExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />
        );
      case ExternalPositionType.KILN_STAKING:
        return <createKilnExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.LIDO_WITHDRAWALS:
        return <createLidoWithdrawalExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.STADER_WITHDRAWALS:
        return (
          <createStaderWithdrawalExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />
        );
      case ExternalPositionType.STAKEWISE_V3:
        return <createStakeWiseExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.PENDLE_V2:
        return <createPendleV2ExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.ALICE:
        return <createAliceExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.GMX_V2_LEVERAGE_TRADING:
        return <createGMXV2ExternalPosition.Summary callOnExternalPositionData={callOnExternalPositionData} />;
      case ExternalPositionType.UNKNOWN:
      case ExternalPositionType.NOTIONAL_V2:
      case ExternalPositionType.MORPHO_BLUE:
      case ExternalPositionType.LIQUITY_DEBT:
      case undefined:
        return <>Unknown External Position</>;
      default:
        Utils.Assertion.never(externalPositionType, `Unknown external position type: ${externalPositionType}`);
    }
  },
  decodeExtensionArgs: (callArgs) => createExternalPositionDecode(callArgs),
};
