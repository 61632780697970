import { TokenSwapTransactionSummary } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { getIntegrationIconUrl } from "@enzymefinance/utils";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useCurrency } from "components/settings/Settings";
import { useMemo } from "react";
import { findTokenValue } from "utils/currency";
import type { IntegrationHandler } from "./types";

export const paraSwapV6SwapExactAmountOut: IntegrationHandler<Integrations.ParaSwapV6.SwapActionArgs> = {
  Description({ args: swapActionArgs }) {
    return swapDescription({ exactAmountOut: true, swapActionArgs });
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.ParaSwapV6.swapExactAmountOutDecode(encodedCallArgs),
};

export const paraSwapV6SwapExactAmountIn: IntegrationHandler<Integrations.ParaSwapV6.SwapActionArgs> = {
  Description({ args: swapActionArgs }) {
    return swapDescription({ exactAmountOut: false, swapActionArgs });
  },
  Label() {
    return <>Transaction summary</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.ParaSwapV6.swapExactAmountInDecode(encodedCallArgs),
};

function swapDescription({
  swapActionArgs,
  exactAmountOut,
}: { swapActionArgs: Integrations.ParaSwapV6.SwapActionArgs; exactAmountOut: boolean }) {
  const { environment } = useGlobals();
  const currency = useCurrency();
  const { assetPrices } = useAssetPrices();

  const swapData = swapActionArgs.swapData;

  const sellAssetAddress = swapData.srcToken;
  const buyAssetAddress = swapData.destToken;

  const sellAsset = environment.getAsset(sellAssetAddress);
  const buyAsset = environment.getAsset(buyAssetAddress);
  const title = "ParaSwap V6";
  const exchangeIcon = getIntegrationIconUrl("paraswap.svg");

  const sellValueInCurrency = useMemo(
    () =>
      findTokenValue({
        assetPrices,
        token: sellAsset,
        value: swapData.fromAmount,
      }),
    [assetPrices, swapData, sellAsset],
  );

  const buyValueInCurrency = useMemo(
    () =>
      findTokenValue({
        assetPrices,
        token: buyAsset,
        value: swapData.toAmount,
      }),
    [assetPrices, buyAsset, swapData],
  );

  return (
    <TokenSwapTransactionSummary
      currency={currency}
      buyAsset={buyAsset}
      sellAsset={sellAsset}
      minIncomingAssetAmount={swapData.toAmount}
      outgoingAssetAmount={swapData.fromAmount}
      buyValueInCurrency={buyValueInCurrency}
      sellValueInCurrency={sellValueInCurrency}
      title={title}
      exchangeIcon={exchangeIcon}
      exactAmountOut={exactAmountOut}
    />
  );
}
