import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Checkbox, FormErrorMessage, NumberInput } from "@enzymefinance/hook-form";
import { Alert, DurationDisplay, ExpandableButton, NumberDisplay, SectionHeading, Tooltip } from "@enzymefinance/ui";
import { InlineLink } from "components/routing/Link";
import { motion } from "framer-motion";
import type { VaultDetailsFragment } from "queries/core";
import { useToggle } from "react-use";
import { VaultDepositUnknownFeeOrPolicyAlert } from "./VaultDepositUnknownFeeOrPolicyAlert";

const twoDaysInSeconds = 60 * 60 * 24 * 2;

function SharesLockUpWarning({ sharesActionTimelock }: { sharesActionTimelock: number }) {
  return (
    <>
      This vault requires that you hold your shares for <DurationDisplay seconds={sharesActionTimelock} /> before
      redeeming or transferring them.
    </>
  );
}

export function VaultDepositFormCommonPart({
  vaultHasUnknownFeeOrPolicy,
  minDeposit,
  maxDeposit,
  vault,
  displaySlippageBelowThresholdWarning,
  displayAllowedExternalPositionTypesWarning,
  vaultEntranceFeeRate,
  displayMaximumSlippage = true,
}: {
  vaultHasUnknownFeeOrPolicy: boolean;
  minDeposit?: bigint;
  maxDeposit?: bigint;
  vault: VaultDetailsFragment;
  displaySlippageBelowThresholdWarning: boolean;
  displayAllowedExternalPositionTypesWarning: boolean;
  vaultEntranceFeeRate?: number;
  displayMaximumSlippage?: boolean;
}) {
  const [advanced, toggleAdvanced] = useToggle(false);

  const denominationAsset = vault.comptroller.denomination;
  const sharesActionTimelock = Number(vault.comptroller.sharesActionTimelock);

  return (
    <>
      {vaultHasUnknownFeeOrPolicy ? <VaultDepositUnknownFeeOrPolicyAlert /> : null}
      {sharesActionTimelock > 0 ? (
        <div className="space-y-1">
          <div className="flex justify-between">
            <Tooltip label={<p className="text-base-content text-sm font-medium">Shares Lock-Up Time</p>}>
              <SharesLockUpWarning sharesActionTimelock={sharesActionTimelock} />
            </Tooltip>
            <DurationDisplay className="text-base-content text-sm" seconds={sharesActionTimelock} />
          </div>
          {sharesActionTimelock >= twoDaysInSeconds && (
            <Alert appearance="warning" title="Extended Lock-Up Period">
              <SharesLockUpWarning sharesActionTimelock={sharesActionTimelock} />
            </Alert>
          )}
        </div>
      ) : (
        <Alert appearance="warning" title="No Lock-Up Period">
          This vault does not require a shares lock-up period and may subsequently be susceptible to various forms of
          share price arbitrage. Deposit only if you know the manager.
        </Alert>
      )}
      {displayAllowedExternalPositionTypesWarning ? (
        <Alert>
          Please only proceed if you are managing your own assets or have a trusted relationship with this manager.
        </Alert>
      ) : null}
      {vaultEntranceFeeRate === undefined ? null : (
        <Alert
          title={
            <p>
              This vault has an entrance fee of{" "}
              <NumberDisplay value={vaultEntranceFeeRate} numberFormat={{ style: "percent" }} />.
            </p>
          }
          className="space-x-4"
        />
      )}
      {typeof minDeposit === "bigint" || typeof maxDeposit === "bigint" ? (
        <div className="space-y-1">
          <Tooltip label={<SectionHeading.Subtitle>Deposit Limits</SectionHeading.Subtitle>}>
            This vault has limits on the amounts of individual deposits.
          </Tooltip>
          <div>
            {typeof minDeposit === "bigint" && minDeposit !== 0n ? (
              <div className="text-base-content flex items-center space-x-2 text-sm">
                <dt>Minimum:</dt>
                <dd>
                  <BigIntDisplay
                    value={minDeposit}
                    numberFormat={{ currency: denominationAsset.symbol }}
                    decimals={denominationAsset.decimals}
                  />
                </dd>
              </div>
            ) : null}
            {typeof maxDeposit === "bigint" && maxDeposit !== 0n ? (
              <div className="text-base-content flex items-center space-x-2 text-sm">
                <dt>Maximum:</dt>
                <dd>
                  <BigIntDisplay
                    value={maxDeposit}
                    numberFormat={{ currency: denominationAsset.symbol }}
                    decimals={denominationAsset.decimals}
                  />
                </dd>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {displayMaximumSlippage ? (
        <ExpandableButton
          appearance="secondary"
          isOpen={advanced}
          handleExpand={toggleAdvanced}
          label="Advanced Settings"
        >
          <div className="space-y-2">
            <NumberInput label="Maximum Slippage" name="maxSlippage" numberFormat={{ style: "percent" }} />
            {displaySlippageBelowThresholdWarning ? (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1, transition: { duration: 0.2 } }}
                exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
              >
                <Alert appearance="warning">
                  The selected maximum slippage is low and may significantly increase the chances of a transaction
                  failure. Consider setting a higher maximum slippage.
                </Alert>
              </motion.div>
            ) : null}
          </div>
        </ExpandableButton>
      ) : null}
      <Checkbox
        name="termsAccepted"
        label={
          <>
            I have read &amp; agree to the{" "}
            <InlineLink to="https://enzyme.finance/terms" appearance="link">
              Terms &amp; Conditions
            </InlineLink>
            .
          </>
        }
      />
      <FormErrorMessage />
    </>
  );
}
