import type { FunctionConfig } from "utils/functionsTypes";

export const depositQueueCancelRequest: FunctionConfig = {
  signature: "function cancelRequest(uint88 _id)",

  Description() {
    return <>Cancel deposit request.</>;
  },

  Label() {
    return <>Cancel Deposit Request</>;
  },
};
