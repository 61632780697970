import { toFunctionSelector } from "viem";
import type { FunctionConfig } from "../../utils/functionsTypes";
import { addAssetManagers } from "./functions/addAssetManagers";
import { addAuthUserForFund } from "./functions/addAuthUserForFund";
import { addManagers } from "./functions/addManager";
import { approve } from "./functions/approve";
import { arbitraryLoanBorrow } from "./functions/arbitraryLoanBorrow";
import { arbitraryLoanRepay } from "./functions/arbitraryLoanRepay";
import { buyBackProtocolFeeShares } from "./functions/buyBackProtocolFeeShares";
import { buyShares } from "./functions/buyShares";
import { callOnExtension } from "./functions/callOnExtension";
import { cancelMigration } from "./functions/cancelMigration";
import { cancelReconfiguration } from "./functions/cancelReconfiguration";
import { claimComp } from "./functions/claimComp";
import { claimOwnership } from "./functions/claimOwnership";
import { createMigrationRequest } from "./functions/createMigrationRequest";
import { createNewFund } from "./functions/createNewFund";
import { createReconfigurationRequest } from "./functions/createReconfigurationRequest";
import { deployGasRelayPaymaster } from "./functions/deployGasRelayPaymaster";
import { deploySharesSplitter } from "./functions/deploySharesSplitter";
import { depositQueueCancelRequest } from "./functions/depositQueueCancelRequest";
import { depositQueueDepositFromQueue } from "./functions/depositQueueDepositFromQueue";
import { depositQueueRequestDeposit } from "./functions/depositQueueRequestDeposit";
import { depositQueueSetDepositorAllowlistId } from "./functions/depositQueueSetDepositorAllowlistId";
import { depositQueueSetMinDepositAssetAmount } from "./functions/depositQueueSetMinDepositAssetAmount";
import { depositQueueSetMinRequestTime } from "./functions/depositQueueSetMinRequestTime";
import { depositToGasRelayPaymaster } from "./functions/depositToGasRelayPaymaster";
import { disablePolicyForFund } from "./functions/disablePolicyForFund";
import { dispatcherOwnedBeaconFactoryDeployProxy } from "./functions/dispatcherOwnedBeaconFactoryDeployProxy";
import { enablePolicyForFund } from "./functions/enablePolicyForFund";
import { exchangeERC20AndBuyShares } from "./functions/exchangeERC20AndBuyShares";
import { exchangeEthAndBuyShares } from "./functions/exchangeEthAndBuyShares";
import { executeMigration } from "./functions/executeMigration";
import { executeReconfiguration } from "./functions/executeReconfiguration";
import { invokeContinuousFeeHookAndPayoutSharesOutstandingForFund } from "./functions/invokeContinuousFeeHookAndPayoutSharesOutstandingForFund";
import { pendleV2RedeemRewards } from "./functions/pendleV2RedeemRewards";
import { queueShutdown } from "./functions/queueShutdown";
import { redeemShares } from "./functions/redeemShares";
import { redeemSharesDetailed } from "./functions/redeemSharesDetailed";
import { redeemSharesForSpecificAssets } from "./functions/redeemSharesForSpecificAssets";
import { redeemSharesInKind } from "./functions/redeemSharesInKind";
import { redemptionQueueDeploy } from "./functions/redemptionQueueFactoryDeploy";
import { redemptionQueueRedeemFromQueue } from "./functions/redemptionQueueRedeemFromQueue";
import { redemptionQueueWithdrawRequest } from "./functions/redemptionQueueWithdrawRequest";
import { removeAssetManagers } from "./functions/removeAssetManagers";
import { removeAuthUserForFund } from "./functions/removeAuthUserForFund";
import { removeManagers } from "./functions/removeManager";
import { removeNominatedOwner } from "./functions/removeNominatedOwner";
import { sharesWrapperRequestRedeem } from "./functions/requestRedeem";
import { setAutoProtocolFeeSharesBuyback } from "./functions/setAutoProtocolFeeSharesBuyback";
import { setBypassableSharesThreshold } from "./functions/setBypassableSharesThreshold";
import { setFreelyTransferableShares } from "./functions/setFreelyTransferableShares";
import { setName } from "./functions/setName";
import { setNominatedOwner } from "./functions/setNominatedOwner";
import { setRecipientForFund } from "./functions/setRecipientForFund";
import { setRedemptionAsset } from "./functions/setRedemptionAsset";
import { sharesSplitterClaimToken } from "./functions/sharesSplitterClaimToken";
import { sharesWrapperCancelRequestDeposit } from "./functions/sharesWrapperCancelRequestDeposit";
import { sharesWrapperCancelRequestRedeem } from "./functions/sharesWrapperCancelRequestRedeem";
import { sharesWrapperDeposit } from "./functions/sharesWrapperDeposit";
import { sharesWrapperDepositAllFromQueue } from "./functions/sharesWrapperDepositAllFromQueue";
import { sharesWrapperDepositApproval } from "./functions/sharesWrapperDepositApproval";
import { sharesWrapperDepositFromQueue } from "./functions/sharesWrapperDepositFromQueue";
import { sharesWrapperDepositRequest } from "./functions/sharesWrapperDepositRequest";
import { sharesWrapperDeploy } from "./functions/sharesWrapperFactoryDeploy";
import { sharesWrapperForceTransfer } from "./functions/sharesWrapperForceTransfer";
import { sharesWrapperKick } from "./functions/sharesWrapperKick";
import { sharesWrapperRedeemFromQueue } from "./functions/sharesWrapperRedeemFromQueue";
import { sharesWrapperSetDepositMode } from "./functions/sharesWrapperSetDepositMode";
import { sharesWrapperSetRedemptionApprovals } from "./functions/sharesWrapperSetRedemptionApprovals";
import { sharesWrapperSetWindowConfig } from "./functions/sharesWrapperSetWindowConfig";
import { sharesWrapperTransferApproval } from "./functions/sharesWrapperTransferApproval";
import { sharesWrapperUseDepositApprovals } from "./functions/sharesWrapperUseDepositApprovals";
import { sharesWrapperUseRedemptionApprovals } from "./functions/sharesWrapperUseRedemptionApprovals";
import { sharesWrapperUseTransferApprovals } from "./functions/sharesWrapperUseTransferApprovals";
import { shutdownGasRelayPaymaster } from "./functions/shutdownGasRelayPaymaster";
import { signalMigration } from "./functions/signalMigration";
import { updatePolicySettingsForFund } from "./functions/updatePolicySettingsForFund";
import { vaultCallOnContract } from "./functions/vaultCallOnContract";

// Obtain sig hashes using cast, e.g. as `cast sig "function addAssetManagers(address[] calldata managers)"`
export const TransactionSigHashes = {
  ADD_ASSET_MANAGERS: "0x3e11553d",
  ADD_AUTH_USER_FOR_FUND: "0xe6c41ec9",
  ADD_MANAGERS: "0x8c5f9e74",
  APPROVE: "0x095ea7b3",
  ARBITRARY_LOAN_BORROW: "0xc5ebeaec",
  ARBITRARY_LOAN_REPAY: "0x371fd8e6",
  BUY_BACK_PROTOCOL_FEE_SHARES: "0xb10ea2b0",
  BUY_SHARES: "0xbeebc5da",
  CALL_ON_EXTENSION: "0x39bf70d1",
  CANCEL_MIGRATION: "0x9c9d48da",
  CANCEL_RECONFIGURATION: "0xd5189add",
  CLAIM_COMP: "0xe9af0292",
  CLAIM_OWNERSHIP: "0x4e71e0c8",
  CREATE_MIGRATION_REQUEST: "0xb8f8a84c",
  CREATE_NEW_FUND: "0xc83980dd",
  CREATE_RECONFIGURATION_REQUEST: "0x4348ab62",
  DEPLOY_GAS_RELAY_PAYMASTER: "0xdb69681f",
  DEPLOY_SHARES_SPLITTER: "0x4f62f4d1",
  DEPOSIT_QUEUE_CANCEL_REQUEST: toFunctionSelector(depositQueueCancelRequest.signature),
  DEPOSIT_QUEUE_DEPOSIT_FROM_QUEUE: toFunctionSelector(depositQueueDepositFromQueue.signature),
  DEPOSIT_QUEUE_REQUEST_DEPOSIT: toFunctionSelector(depositQueueRequestDeposit.signature),
  DEPOSIT_QUEUE_SET_DEPOSITOR_ALLOWLIST_ID: toFunctionSelector(depositQueueSetDepositorAllowlistId.signature),
  DEPOSIT_QUEUE_SET_MIN_DEPOSIT_ASSET_AMOUNT: toFunctionSelector(depositQueueSetMinDepositAssetAmount.signature),
  DEPOSIT_QUEUE_SET_MIN_REQUEST_TIME: toFunctionSelector(depositQueueSetMinRequestTime.signature),
  DEPOSIT_TO_GAS_RELAY_PAYMASTER: "0x716d4da4",
  DISABLE_POLICY_FOR_FUND: "0x45d582e7",
  DISPATCHER_OWNED_BEACON_FACTORY: toFunctionSelector(dispatcherOwnedBeaconFactoryDeployProxy.signature),
  ENABLE_POLICY_FORFUND: "0x48f35209",
  EXCHANGE_ETH_AND_BUY_SHARES: "0x9a98f01b",
  EXCHANGE_ERC20_AND_BUY_SHARES: "0xc9560f3f",
  EXECUTE_MIGRATION: "0x38b3eb1b",
  EXECUTE_RECONFIGURATION: "0xff845718",
  INVOKE_CONTINUOUS_FEE_HOOK_AND_PAYOUT_SHARES_OUTSTANDING_FOR_FUND: "0xace7cc9c",
  PENDLE_V2_REDEEM_REWARDS: "0x9262187b",
  QUEUE_SHUTDOWN: "0xfc0e74d1",
  REDEEM_SHARES_DETAILED: "0x6487b384",
  REDEEM_SHARES_FOR_SPECIFIC_ASSETS: "0x3462fcc1",
  REDEEM_SHARES_IN_KIND: "0x6af8e7eb",
  REDEEM_SHARES: "0xb460481d",
  REDEMPTION_QUEUE_DEPLOY: "0x9e912fd7",
  REDEMPTION_QUEUE_REDEEM_FROM_QUEUE: "0xe77e7204",
  REDEMPTION_QUEUE_WITHDRAW_REQUEST: "0x74899a7e",
  REMOVE_ASSET_MANAGERS: "0x64cb36cb",
  REMOVE_AUTH_USER_FOR_FUND: "0x501cc761",
  REMOVE_MANAGERS: "0x10f3ee29",
  REMOVE_NOMINATED_OWNER: "0x8156eecf",
  SET_AUTO_PROTOCOL_FEE_SHARES_BUYBACK: "0x2dc7a3a0",
  SET_BYPASSABLE_SHARES_THRESHOLD: "0xad6e5f48",
  SET_FREELY_TRANSFERABLE_SHARES: "0x7c81ac2d",
  SET_NAME: "0xc47f0027",
  SET_NOMINATED_OWNER: "0x728e17a0",
  SET_RECIPIENT_FOR_FUND: "0x8c55f80f",
  SHARES_SPLITTER_CLAIM_TOKEN: "0x32f289cf",
  SHARES_WRAPPER_CANCEL_REQUEST_DEPOSIT: "0x24248775",
  SHARES_WRAPPER_CANCEL_REQUEST_REDEEM: "0x58205209",
  SHARES_WRAPPER_DEPLOY: "0xeb5d5aa9",
  SHARES_WRAPPER_DEPOSIT_ALL_FROM_QUEUE: "0x2f21802f",
  SHARES_WRAPPER_DEPOSIT_APPROVAL: "0x34c94506",
  SHARES_WRAPPER_DEPOSIT_FROM_QUEUE: "0x8530503e",
  SHARES_WRAPPER_DEPOSIT_REQUEST: "0x5696bdae",
  SHARES_WRAPPER_DEPOSIT: "0x0efe6a8b",
  SHARES_WRAPPER_FORCE_TRANSFER: "0xb3cb06c3",
  SHARES_WRAPPER_KICK: "0x96c55175",
  SHARES_WRAPPER_REDEEM_FROM_QUEUE: "0xca4ca8fa",
  REQUEST_REDEEM: "0xaa2f892d",
  SHARES_WRAPPER_SET_DEPOSIT_MODE: "0xdd62f839",
  SHARES_WRAPPER_SET_REDEMPTION_APPROVALS: "0x8d1d24da",
  SET_REDEMPTION_ASSET: "0x7ba06931",
  SHARES_WRAPPER_SET_WINDOW_CONFIG: "0x09f8ca5c",
  SHARES_WRAPPER_TRANSFER_APPROVAL: "0x469df117",
  SHARES_WRAPPER_USE_DEPOSIT_APPROVALS: "0xb9a3abdb",
  SHARES_WRAPPER_USE_REDEMPTION_APPROVALS: "0x4d947471",
  SHARES_WRAPPER_USE_TRANSFER_APPROVALS: "0x15fd7a52",
  SHUTDOWN_GAS_RELAY_PAYMASTER: "0xf9005af3",
  SIGNAL_MIGRATION: "0xd2456de1",
  UPDATE_POLICY_SETTINGS_FOR_FUND: "0x4ed28b3f",
  VAULT_CALL_ON_CONTRACT: "0xe572ced1",
} as const;

export type TransactionConfigKeyType = (typeof TransactionSigHashes)[keyof typeof TransactionSigHashes];

export const transactionConfigs: Record<TransactionConfigKeyType, FunctionConfig<any>> = {
  [TransactionSigHashes.ADD_ASSET_MANAGERS]: addAssetManagers,
  [TransactionSigHashes.ADD_AUTH_USER_FOR_FUND]: addAuthUserForFund,
  [TransactionSigHashes.ADD_MANAGERS]: addManagers,
  [TransactionSigHashes.APPROVE]: approve,
  [TransactionSigHashes.ARBITRARY_LOAN_BORROW]: arbitraryLoanBorrow,
  [TransactionSigHashes.ARBITRARY_LOAN_REPAY]: arbitraryLoanRepay,
  [TransactionSigHashes.BUY_BACK_PROTOCOL_FEE_SHARES]: buyBackProtocolFeeShares,
  [TransactionSigHashes.BUY_SHARES]: buyShares,
  [TransactionSigHashes.CALL_ON_EXTENSION]: callOnExtension,
  [TransactionSigHashes.CANCEL_MIGRATION]: cancelMigration,
  [TransactionSigHashes.CANCEL_RECONFIGURATION]: cancelReconfiguration,
  [TransactionSigHashes.CLAIM_COMP]: claimComp,
  [TransactionSigHashes.CLAIM_OWNERSHIP]: claimOwnership,
  [TransactionSigHashes.CREATE_MIGRATION_REQUEST]: createMigrationRequest,
  [TransactionSigHashes.CREATE_NEW_FUND]: createNewFund,
  [TransactionSigHashes.CREATE_RECONFIGURATION_REQUEST]: createReconfigurationRequest,
  [TransactionSigHashes.DEPLOY_GAS_RELAY_PAYMASTER]: deployGasRelayPaymaster,
  [TransactionSigHashes.DEPLOY_SHARES_SPLITTER]: deploySharesSplitter,
  [TransactionSigHashes.DEPOSIT_QUEUE_CANCEL_REQUEST]: depositQueueCancelRequest,
  [TransactionSigHashes.DEPOSIT_QUEUE_DEPOSIT_FROM_QUEUE]: depositQueueDepositFromQueue,
  [TransactionSigHashes.DEPOSIT_QUEUE_REQUEST_DEPOSIT]: depositQueueRequestDeposit,
  [TransactionSigHashes.DEPOSIT_QUEUE_SET_DEPOSITOR_ALLOWLIST_ID]: depositQueueSetDepositorAllowlistId,
  [TransactionSigHashes.DEPOSIT_QUEUE_SET_MIN_DEPOSIT_ASSET_AMOUNT]: depositQueueSetMinDepositAssetAmount,
  [TransactionSigHashes.DEPOSIT_QUEUE_SET_MIN_REQUEST_TIME]: depositQueueSetMinRequestTime,
  [TransactionSigHashes.DEPOSIT_TO_GAS_RELAY_PAYMASTER]: depositToGasRelayPaymaster,
  [TransactionSigHashes.DISABLE_POLICY_FOR_FUND]: disablePolicyForFund,
  [TransactionSigHashes.DISPATCHER_OWNED_BEACON_FACTORY]: dispatcherOwnedBeaconFactoryDeployProxy,
  [TransactionSigHashes.ENABLE_POLICY_FORFUND]: enablePolicyForFund,
  [TransactionSigHashes.EXCHANGE_ETH_AND_BUY_SHARES]: exchangeEthAndBuyShares,
  [TransactionSigHashes.EXCHANGE_ERC20_AND_BUY_SHARES]: exchangeERC20AndBuyShares,
  [TransactionSigHashes.EXECUTE_MIGRATION]: executeMigration,
  [TransactionSigHashes.EXECUTE_RECONFIGURATION]: executeReconfiguration,
  [TransactionSigHashes.INVOKE_CONTINUOUS_FEE_HOOK_AND_PAYOUT_SHARES_OUTSTANDING_FOR_FUND]:
    invokeContinuousFeeHookAndPayoutSharesOutstandingForFund,
  [TransactionSigHashes.PENDLE_V2_REDEEM_REWARDS]: pendleV2RedeemRewards,
  [TransactionSigHashes.QUEUE_SHUTDOWN]: queueShutdown,
  [TransactionSigHashes.REDEEM_SHARES_DETAILED]: redeemSharesDetailed,
  [TransactionSigHashes.REDEEM_SHARES_FOR_SPECIFIC_ASSETS]: redeemSharesForSpecificAssets,
  [TransactionSigHashes.REDEEM_SHARES_IN_KIND]: redeemSharesInKind,
  [TransactionSigHashes.REDEEM_SHARES]: redeemShares,
  [TransactionSigHashes.REDEMPTION_QUEUE_DEPLOY]: redemptionQueueDeploy,
  [TransactionSigHashes.REDEMPTION_QUEUE_REDEEM_FROM_QUEUE]: redemptionQueueRedeemFromQueue,
  [TransactionSigHashes.REDEMPTION_QUEUE_WITHDRAW_REQUEST]: redemptionQueueWithdrawRequest,
  [TransactionSigHashes.REMOVE_ASSET_MANAGERS]: removeAssetManagers,
  [TransactionSigHashes.REMOVE_AUTH_USER_FOR_FUND]: removeAuthUserForFund,
  [TransactionSigHashes.REMOVE_MANAGERS]: removeManagers,
  [TransactionSigHashes.REMOVE_NOMINATED_OWNER]: removeNominatedOwner,
  [TransactionSigHashes.SET_AUTO_PROTOCOL_FEE_SHARES_BUYBACK]: setAutoProtocolFeeSharesBuyback,
  [TransactionSigHashes.SET_BYPASSABLE_SHARES_THRESHOLD]: setBypassableSharesThreshold,
  [TransactionSigHashes.SET_FREELY_TRANSFERABLE_SHARES]: setFreelyTransferableShares,
  [TransactionSigHashes.SET_NAME]: setName,
  [TransactionSigHashes.SET_NOMINATED_OWNER]: setNominatedOwner,
  [TransactionSigHashes.SET_RECIPIENT_FOR_FUND]: setRecipientForFund,
  [TransactionSigHashes.SHARES_SPLITTER_CLAIM_TOKEN]: sharesSplitterClaimToken,
  [TransactionSigHashes.SHARES_WRAPPER_CANCEL_REQUEST_DEPOSIT]: sharesWrapperCancelRequestDeposit,
  [TransactionSigHashes.SHARES_WRAPPER_CANCEL_REQUEST_REDEEM]: sharesWrapperCancelRequestRedeem,
  [TransactionSigHashes.SHARES_WRAPPER_DEPLOY]: sharesWrapperDeploy,
  [TransactionSigHashes.SHARES_WRAPPER_DEPOSIT_ALL_FROM_QUEUE]: sharesWrapperDepositAllFromQueue,
  [TransactionSigHashes.SHARES_WRAPPER_DEPOSIT_APPROVAL]: sharesWrapperDepositApproval,
  [TransactionSigHashes.SHARES_WRAPPER_DEPOSIT_FROM_QUEUE]: sharesWrapperDepositFromQueue,
  [TransactionSigHashes.SHARES_WRAPPER_DEPOSIT_REQUEST]: sharesWrapperDepositRequest,
  [TransactionSigHashes.SHARES_WRAPPER_DEPOSIT]: sharesWrapperDeposit,
  [TransactionSigHashes.SHARES_WRAPPER_FORCE_TRANSFER]: sharesWrapperForceTransfer,
  [TransactionSigHashes.SHARES_WRAPPER_KICK]: sharesWrapperKick,
  [TransactionSigHashes.SHARES_WRAPPER_REDEEM_FROM_QUEUE]: sharesWrapperRedeemFromQueue,
  [TransactionSigHashes.REQUEST_REDEEM]: sharesWrapperRequestRedeem,
  [TransactionSigHashes.SHARES_WRAPPER_SET_DEPOSIT_MODE]: sharesWrapperSetDepositMode,
  [TransactionSigHashes.SHARES_WRAPPER_SET_REDEMPTION_APPROVALS]: sharesWrapperSetRedemptionApprovals,
  [TransactionSigHashes.SET_REDEMPTION_ASSET]: setRedemptionAsset,
  [TransactionSigHashes.SHARES_WRAPPER_SET_WINDOW_CONFIG]: sharesWrapperSetWindowConfig,
  [TransactionSigHashes.SHARES_WRAPPER_TRANSFER_APPROVAL]: sharesWrapperTransferApproval,
  [TransactionSigHashes.SHARES_WRAPPER_USE_DEPOSIT_APPROVALS]: sharesWrapperUseDepositApprovals,
  [TransactionSigHashes.SHARES_WRAPPER_USE_REDEMPTION_APPROVALS]: sharesWrapperUseRedemptionApprovals,
  [TransactionSigHashes.SHARES_WRAPPER_USE_TRANSFER_APPROVALS]: sharesWrapperUseTransferApprovals,
  [TransactionSigHashes.SHUTDOWN_GAS_RELAY_PAYMASTER]: shutdownGasRelayPaymaster,
  [TransactionSigHashes.SIGNAL_MIGRATION]: signalMigration,
  [TransactionSigHashes.UPDATE_POLICY_SETTINGS_FOR_FUND]: updatePolicySettingsForFund,
  [TransactionSigHashes.VAULT_CALL_ON_CONTRACT]: vaultCallOnContract,
};
