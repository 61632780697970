import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Skeleton } from "@enzymefinance/ui";
import { useNetwork } from "components/providers/NetworkProvider";
import { useDepositQueueVaultDetailsQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";

export const depositQueueRequestDeposit: FunctionConfig<{ _depositAssetAmount: bigint }> = {
  signature: "function requestDeposit(uint128 _depositAssetAmount)",
  Description({ inputs, address }) {
    const { environment } = useNetwork();
    const depositAssetAmount = BigInt(inputs._depositAssetAmount);

    const query = useDepositQueueVaultDetailsQuery({
      variables: { id: toAddress(address) },
    });

    const details = query.data?.singleAssetDepositQueue?.vault;
    const depositAsset = query.data?.singleAssetDepositQueue?.depositAsset
      ? environment.getAsset(query.data.singleAssetDepositQueue.depositAsset.id)
      : undefined;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Request Deposit of{" "}
        <BigIntDisplay
          numberFormat={{ currency: depositAsset?.symbol, maximumFractionDigits: 20 }}
          value={depositAssetAmount}
          decimals={depositAsset?.decimals}
        />{" "}
        to <b>{details?.name ?? "this vault"}</b>.
      </>
    );
  },

  Label() {
    return <>Request Deposit</>;
  },
};
