import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import type { Asset } from "@enzymefinance/environment";
import { EtherscanLink } from "@enzymefinance/ethereum-ui";
import { Utils } from "@enzymefinance/sdk";
import { Address as AddressUtils } from "@enzymefinance/sdk/Utils";
import { Alert, Button, Modal, useBoolean } from "@enzymefinance/ui";
import { LoadingScreen } from "components/common/LoadingScreen";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { TransactionModal, defaultIsOpen, useTransactionModal } from "components/transactions/TransactionModal";
import { VaultDepositDenominationAssetForm } from "components/vault/deposit/VaultDepositDenominationAssetForm";
import { VaultDepositNativeTokenForm } from "components/vault/deposit/VaultDepositNativeTokenForm";
import { useArbitraryLoansEnabledForVaultQuery, useIsDepositorSignatureRequiredQuery } from "queries/backend";
import type { FeeFragment, VaultDetailsFragment } from "queries/core";
import { useVaultFeesQuery, useVaultPoliciesQuery } from "queries/core";
import { useCallback, useMemo, useState } from "react";
import type { SubsetOf } from "types/graphql";
import { client as backendClient } from "utils/backend";
import { useDepositQueueUserRequest } from "utils/hooks/useDepositQueueRequest";
import { useExistingVaultConfig } from "utils/hooks/useExistingVaultConfig";
import { useIsAllowedDepositor } from "utils/hooks/useIsAllowedDepositor";
import { useMinMaxInvestmentsPolicySettings } from "utils/hooks/useMinMaxInvestmentsPolicySettings";
import { useSharesWrapperDepositInfo } from "utils/hooks/useSharesWrapperDepositInfo";
import { useSignaledMigration } from "utils/hooks/useSignaledMigration";
import { useSignaledReconfiguration } from "utils/hooks/useSignaledReconfiguration";
import { useSingleAssetDepositQueueInfo } from "utils/hooks/useSingleAssetDepositQueueInfo";
import { useTokenSupply } from "utils/hooks/useTokenSupply";
import { minimumNumberOfShares } from "utils/vault";
import { isAddressEqual } from "viem";
import { VaultDepositAssetForm } from "./VaultDepositAssetForm";
import { VaultDepositDepositQueueForm } from "./VaultDepositDepositQueueForm";
import { VaultDepositSharesWrapperForm } from "./VaultDepositSharesWrapperForm";
import { VaultDepositSignatureForm } from "./VaultDepositSignatureForm";
import { DepositTokenPicker } from "./components/DepositTokenPicker";

interface VaultDepositModalProps {
  close: () => void;
  isOpen: boolean;
  signerAddress: Address;
  showDenominationInvest?: boolean;
  vault: VaultDetailsFragment;
}

export function VaultDepositModal({ close, isOpen, vault, signerAddress }: VaultDepositModalProps) {
  const { network, deployment, client, environment } = useNetwork();
  const transaction = useTransactionModal();
  const { currentReleaseId, contracts } = useGlobals();
  const [depositAsset, setDepositAsset] = useState<Asset>(environment.getAsset(vault.comptroller.denomination.id));
  const [depositAmount, setDepositAmount] = useState(0n);

  const [isFlexibleLoanModalOpen, openFlexibleLoanModal, closeFlexibleLoanModal] = useBoolean(false);
  const isTransactionOpen = useMemo(() => defaultIsOpen(transaction.state), [transaction.state]);
  const dismiss = useCallback(() => {
    if (isFlexibleLoanModalOpen) {
      closeFlexibleLoanModal();
    } else {
      close();
    }
    transaction.send("CANCEL");
  }, [close, closeFlexibleLoanModal, transaction, isFlexibleLoanModalOpen]);

  // check if vault is flex loan enabled
  const flexLoansQuery = useArbitraryLoansEnabledForVaultQuery({
    client: backendClient,
    variables: {
      network: deployment,
      comptroller: toAddress(vault.comptroller.id),
      ownerId: toAddress(vault.owner.id),
    },
  });

  const sharesWrapperDepositInfo = useSharesWrapperDepositInfo({
    account: signerAddress,
    vaultId: toAddress(vault.id),
    comptroller: toAddress(vault.comptroller.id),
  });

  const sharesWrapperInfo = sharesWrapperDepositInfo?.data;

  const depositQueueQuery = useSingleAssetDepositQueueInfo({
    vaultId: toAddress(vault.id),
    comptroller: toAddress(vault.comptroller.id),
    account: signerAddress,
  });

  const denominationAssetId = toAddress(vault.comptroller.denomination.id);

  const depositRequest = useDepositQueueUserRequest(client, {
    address: sharesWrapperInfo?.sharesWrapperAddress,
    depositAssetAddress: denominationAssetId,
    signerAddress,
  });

  const recipientAddress = useMemo(() => {
    if (sharesWrapperInfo !== undefined) {
      return sharesWrapperInfo.sharesWrapperAddress;
    }

    return signerAddress;
  }, [sharesWrapperInfo, signerAddress]);

  const isArbitraryLoanAvailable = flexLoansQuery.data?.arbitraryLoansEnabledForVault.isFeatureEnabled ?? false;

  const signaledMigrationQuery = useSignaledMigration(client, {
    dispatcher: contracts.Dispatcher,
    vaultProxy: toAddress(vault.id),
  });
  const isMigrationPending = signaledMigrationQuery.data;
  const signaledReconfigurationQuery = useSignaledReconfiguration(client, {
    fundDeployer: contracts.FundDeployer,
    release: toAddress(vault.release.id),
    vaultProxy: toAddress(vault.id),
  });
  const isReconfigurationPending = signaledReconfigurationQuery.data;

  const isCurrentRelease = Utils.Address.safeSameAddress(currentReleaseId, vault.release.id);

  const isAllowedDepositorQuery = useIsAllowedDepositor(client, {
    allowedDepositRecipientsPolicy: contracts.AllowedDepositRecipientsPolicy,
    comptrollerProxy: toAddress(vault.comptroller.id),
    recipient: recipientAddress,
    release: toAddress(vault.release.id),
    currentReleaseId: toAddress(currentReleaseId),
    policyManager: contracts.PolicyManager,
  });

  const isDepositorSignatureRequiredQuery = useIsDepositorSignatureRequiredQuery({
    client: backendClient,
    variables: { vaultAddress: toAddress(vault.id), walletAddress: signerAddress },
  });

  const { data: minMaxInvestmentsPolicySettings } = useMinMaxInvestmentsPolicySettings(client, {
    comptrollerProxy: toAddress(vault.comptroller.id),
    minMaxInvestmentsPolicy: contracts.MinMaxInvestmentPolicy,
    policyManager: contracts.PolicyManager,
  });

  const vaultFeesQuery = useVaultFeesQuery({
    variables: { comptroller: vault.comptroller.id },
  });

  const policySettingsQuery = useVaultPoliciesQuery({
    variables: { comptroller: vault.comptroller.id },
  });

  const vaultHasUnknownFeeOrPolicy = useMemo(() => {
    return (
      vaultFeesQuery.data?.comptroller?.fees.find((fee) => fee.feeType === "Unknown") !== undefined ||
      policySettingsQuery.data?.comptroller?.policies.find((policy) => policy.policyType === "Unknown") !== undefined
    );
  }, [policySettingsQuery.data?.comptroller?.policies, vaultFeesQuery.data?.comptroller?.fees]);

  const sharesBalanceQuery = useTokenSupply(client, toAddress(vault.id));

  const sharesBalanceTooLow = useMemo(
    () =>
      (typeof sharesBalanceQuery.data === "bigint" &&
        !(sharesBalanceQuery.data === 0n) &&
        sharesBalanceQuery.data <= minimumNumberOfShares) ??
      true,
    [sharesBalanceQuery.data],
  );

  const vaultEntranceFee = useMemo(() => {
    const entranceRateBurnFee = vaultFeesQuery.data?.comptroller?.fees.find(
      (fee) => fee.__typename === "EntranceRateBurnFee",
    ) as SubsetOf<FeeFragment, "EntranceRateBurnFee"> | undefined;
    const entranceRateDirectFee = vaultFeesQuery.data?.comptroller?.fees.find(
      (fee) => fee.__typename === "EntranceRateDirectFee",
    ) as SubsetOf<FeeFragment, "EntranceRateDirectFee"> | undefined;

    // Note: a vault should only ever have one entrance fee
    return entranceRateBurnFee ?? entranceRateDirectFee;
  }, [vaultFeesQuery.data]);

  const areDepositsDisabled = useMemo(() => {
    if (
      minMaxInvestmentsPolicySettings?.minInvestmentAmount === 0n &&
      minMaxInvestmentsPolicySettings.maxInvestmentAmount === 0n
    ) {
      return true;
    }

    return false;
  }, [minMaxInvestmentsPolicySettings]);

  const showAllowedDepositorsWarning =
    isCurrentRelease &&
    ((isAllowedDepositorQuery.data === false && depositQueueQuery === null) ||
      (depositQueueQuery !== null &&
        depositQueueQuery.data.allowedDepositors !== undefined &&
        !depositQueueQuery.data.allowedDepositors.includes(signerAddress)));
  const showWarning =
    !isCurrentRelease ||
    showAllowedDepositorsWarning ||
    isMigrationPending ||
    isReconfigurationPending ||
    areDepositsDisabled;

  const warningTitle = showAllowedDepositorsWarning
    ? "Access Restricted!"
    : isMigrationPending
      ? "Upgrade Pending"
      : isReconfigurationPending
        ? "Reconfiguration Pending"
        : sharesBalanceTooLow
          ? "Shares Balance Too Low"
          : "Deposits Closed";

  const warningMessage = showAllowedDepositorsWarning ? (
    <>
      This vault operates a depositor whitelist and your wallet address (
      <EtherscanLink hash={signerAddress} network={network} />) is not on it. Please confirm you are connected with the
      correct wallet. If you believe you are receiving this message in error, please contact the vault manager.
    </>
  ) : isMigrationPending ? (
    "This vault has an upgrade pending. Deposits are therefore temporarily disabled."
  ) : isReconfigurationPending ? (
    "This vault has a reconfiguration pending. Deposits are therefore temporarily disabled."
  ) : isCurrentRelease ? (
    sharesBalanceTooLow ? (
      "The shares balance of this vault is too low to deposit."
    ) : (
      "This vault has been configured to reject all deposits. If you believe this is an error, please contact the vault manager."
    )
  ) : (
    "This vault has not been upgraded to the latest version. It is not possible to deposit from this app."
  );

  const vaultEntranceFeeRate = vaultEntranceFee?.rate === undefined ? undefined : Number(vaultEntranceFee.rate);

  const existingVaultConfig = useExistingVaultConfig({ comptrollerProxy: vault.comptroller.id });
  const isAllowedExternalPositionTypesEnabledAndEmpty = useMemo(
    () =>
      Array.isArray(existingVaultConfig?.allowedExternalPositionTypesPolicy?.items) &&
      existingVaultConfig?.allowedExternalPositionTypesPolicy?.items.length === 0,
    [existingVaultConfig?.allowedExternalPositionTypesPolicy],
  );
  const displayAllowedExternalPositionTypesWarning =
    isAllowedExternalPositionTypesEnabledAndEmpty ||
    [
      AddressUtils.asAddress("0x7c957c9d9679baed0d5bbf76ec470895622fcab7"), // stUSD Restaking Vault on Nektar
      AddressUtils.asAddress("0x9c1837b56314c3cf45f78c8720ef2267f4a80450"), // dlcBTC Bitcoin Staking pool
      AddressUtils.asAddress("0x42c61e8b1c2f1a6a298838db1887e0e2523eef53"), // Re7 Nektar ETH
    ].some((address) => isAddressEqual(address, AddressUtils.asAddress(vault.id)));

  const closeAll = useCallback(() => {
    close();
    closeFlexibleLoanModal();
  }, [close, closeFlexibleLoanModal]);

  const isLoading =
    Boolean(sharesWrapperDepositInfo?.loading) &&
    flexLoansQuery.loading &&
    isDepositorSignatureRequiredQuery.loading &&
    depositQueueQuery?.loading;

  return (
    <>
      {isTransactionOpen ? <TransactionModal {...transaction} /> : null}
      <Modal isOpen={isOpen} dismiss={dismiss} title={isFlexibleLoanModalOpen ? "Flexible loans" : "Deposit"}>
        {isLoading ? (
          <LoadingScreen />
        ) : showWarning ? (
          <>
            <Modal.Body>
              <Alert title={warningTitle}>{warningMessage}</Alert>
            </Modal.Body>
            <Modal.Actions>
              <Button onClick={dismiss}>Dismiss</Button>
            </Modal.Actions>
          </>
        ) : isDepositorSignatureRequiredQuery.data?.isDepositorSignatureRequired === true ? (
          <VaultDepositSignatureForm close={closeAll} signerAddress={signerAddress} vault={vault} />
        ) : sharesWrapperInfo !== undefined ? (
          <VaultDepositSharesWrapperForm
            close={closeAll}
            start={transaction.start}
            state={transaction.state}
            vault={vault}
            depositAmount={depositAmount}
            setDepositAmount={setDepositAmount}
            maxDeposit={minMaxInvestmentsPolicySettings?.maxInvestmentAmount}
            minDeposit={minMaxInvestmentsPolicySettings?.minInvestmentAmount}
            isFlexibleLoanVault={isArbitraryLoanAvailable}
            isFlexibleLoanModalOpen={isFlexibleLoanModalOpen}
            openFlexibleLoanModal={openFlexibleLoanModal}
            sharesWrapper={sharesWrapperInfo}
            depositRequestValue={depositRequest.data || 0n}
            vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
          />
        ) : depositQueueQuery?.data !== undefined ? (
          <VaultDepositDepositQueueForm
            close={closeAll}
            startTransaction={transaction.start}
            state={transaction.state}
            vault={vault}
            depositQueueDepositInfo={depositQueueQuery.data}
            isFlexibleLoanVault={isArbitraryLoanAvailable}
            isFlexibleLoanModalOpen={isFlexibleLoanModalOpen}
            openFlexibleLoanModal={openFlexibleLoanModal}
            vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
            displayAllowedExternalPositionTypesWarning={displayAllowedExternalPositionTypesWarning}
            vaultEntranceFeeRate={vaultEntranceFeeRate}
          />
        ) : isAddressEqual(depositAsset.id, denominationAssetId) ? (
          <VaultDepositDenominationAssetForm
            close={close}
            start={transaction.start}
            state={transaction.state}
            vault={vault}
            changeAssetNode={
              <DepositTokenPicker vault={vault} depositAsset={depositAsset} setDepositAsset={setDepositAsset} />
            }
            depositAmount={depositAmount}
            setDepositAmount={setDepositAmount}
            maxDeposit={minMaxInvestmentsPolicySettings?.maxInvestmentAmount}
            minDeposit={minMaxInvestmentsPolicySettings?.minInvestmentAmount}
            isFlexibleLoanVault={isArbitraryLoanAvailable}
            isFlexibleLoanModalOpen={isFlexibleLoanModalOpen}
            openFlexibleLoanModal={openFlexibleLoanModal}
            vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
            displayAllowedExternalPositionTypesWarning={displayAllowedExternalPositionTypesWarning}
            vaultEntranceFeeRate={vaultEntranceFeeRate}
          />
        ) : isAddressEqual(depositAsset.id, environment.network.currency.nativeToken.id) ? (
          <VaultDepositNativeTokenForm
            close={closeAll}
            startTxData={transaction.startTxData}
            state={transaction.state}
            vault={vault}
            changeAssetNode={
              <DepositTokenPicker vault={vault} depositAsset={depositAsset} setDepositAsset={setDepositAsset} />
            }
            maxDeposit={minMaxInvestmentsPolicySettings?.maxInvestmentAmount}
            minDeposit={minMaxInvestmentsPolicySettings?.minInvestmentAmount}
            isFlexibleLoanVault={isArbitraryLoanAvailable}
            isFlexibleLoanModalOpen={isFlexibleLoanModalOpen}
            openFlexibleLoanModal={openFlexibleLoanModal}
            vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
            displayAllowedExternalPositionTypesWarning={displayAllowedExternalPositionTypesWarning}
            vaultEntranceFeeRate={vaultEntranceFeeRate}
          />
        ) : (
          <VaultDepositAssetForm
            close={closeAll}
            start={transaction.start}
            startTxData={transaction.startTxData}
            state={transaction.state}
            vault={vault}
            depositAsset={depositAsset}
            changeAssetNode={
              <DepositTokenPicker vault={vault} depositAsset={depositAsset} setDepositAsset={setDepositAsset} />
            }
            depositAmount={depositAmount}
            setDepositAmount={setDepositAmount}
            maxDeposit={minMaxInvestmentsPolicySettings?.maxInvestmentAmount}
            minDeposit={minMaxInvestmentsPolicySettings?.minInvestmentAmount}
            isFlexibleLoanVault={isArbitraryLoanAvailable}
            isFlexibleLoanModalOpen={isFlexibleLoanModalOpen}
            openFlexibleLoanModal={openFlexibleLoanModal}
            vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
            displayAllowedExternalPositionTypesWarning={displayAllowedExternalPositionTypesWarning}
            vaultEntranceFeeRate={vaultEntranceFeeRate}
          />
        )}
      </Modal>
    </>
  );
}
