import type { FunctionConfig } from "utils/functionsTypes";

export const depositQueueSetMinRequestTime: FunctionConfig = {
  signature: "function setMinRequestTime(uint64 _minRequestTime)",

  Description() {
    return <>Set min request time</>;
  },

  Label() {
    return <>Set Min Request Time</>;
  },
};
