import type { Address, Environment } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { useSigner } from "components/connection/Connection.js";
import { useGlobals } from "components/providers/GlobalsProvider";
import dayjs from "dayjs";
import { type DepositQueueDepositInfoQuery, useDepositQueueDepositInfoQuery } from "queries/core";
import { getDepositQueue } from "utils/depositQueue";
import { parseUnits } from "viem";

interface DepositQueueDepositInfoProps {
  vaultId: Address;
  comptroller: Address;
  account: Address;
}

export interface SharedDepositQueueDepositInfo {
  depositQueueAddress: Address;
  depositAssetAddress?: Address;
  shutdown: boolean;
  depositAssetAmount: bigint;
  minDepositAmount: bigint;
  minRequestTime: number;
  allowedDepositors?: Address[];
  requests: ReadonlyArray<{
    id: string;
    requestId: bigint;
    depositAssetAmount: bigint;
    cancelableAt: dayjs.Dayjs;
    isCancelable: boolean;
  }>;
}

export function useSingleAssetDepositQueueInfo({ vaultId, comptroller, account }: DepositQueueDepositInfoProps): {
  data: SharedDepositQueueDepositInfo;
  loading: boolean;
} | null {
  const [signerAddress] = useSigner();
  const { environment } = useGlobals();

  const { data: depositInfo, loading } = useDepositQueueDepositInfoQuery({
    variables: {
      vault: vaultId,
      comptroller,
      account,
    },
    skip: signerAddress === undefined,
  });

  if (depositInfo === undefined) {
    return null;
  }

  if (!depositInfo.allowedDepositRecipientsPolicies[0]) {
    const depositQueue = depositInfo.singleAssetDepositQueues[0];
    if (depositQueue === undefined) {
      return null;
    }

    return {
      loading,
      data: getDepositQueueParsedData(depositQueue, environment),
    };
  }

  if (!depositInfo.singleAssetDepositQueues[0]) {
    return null;
  }

  const allowedDepositRecipientsPolicy = depositInfo.allowedDepositRecipientsPolicies[0];

  const depositQueue = getDepositQueue({
    allowedDepositRecipientsPolicy,
    singleAssetDepositQueues: depositInfo.singleAssetDepositQueues,
  });

  if (depositQueue === null) {
    return null;
  }

  return {
    loading,
    data: getDepositQueueParsedData(depositQueue, environment),
  };
}

function getDepositQueueParsedData(
  depositQueue: DepositQueueDepositInfoQuery["singleAssetDepositQueues"][number],
  environment: Environment,
) {
  const depositAsset = depositQueue.depositAsset ? environment.getAsset(depositQueue.depositAsset.id) : undefined;
  const allowedDepositors = depositQueue.depositorAllowlist
    ? depositQueue.depositorAllowlist.items.map((item) => toAddress(item))
    : undefined;

  const now = dayjs();

  return {
    depositQueueAddress: toAddress(depositQueue.id),
    depositAssetAddress: depositAsset?.id,
    shutdown: depositQueue.shutdown,
    minDepositAmount: BigInt(depositQueue.minDepositAssetAmount),
    depositAssetAmount: depositQueue.requests.reduce(
      (carry, item) => carry + parseUnits(item.depositAssetAmount.amount, item.depositAssetAmount.asset.decimals),
      0n,
    ),
    minRequestTime: depositQueue.minRequestTime,
    allowedDepositors,
    requests: depositQueue.requests.map((request) => {
      const cancelableAt = dayjs.unix(request.cancelableAt);
      return {
        id: request.id,
        cancelableAt,
        isCancelable: now.isAfter(cancelableAt) || depositQueue.shutdown || request.bypassed,
        depositAssetAmount: parseUnits(request.depositAssetAmount.amount, request.depositAssetAmount.asset.decimals),
        requestId: BigInt(request.requestId),
      };
    }),
  };
}
