import type { FunctionConfig } from "utils/functionsTypes";

export const depositQueueSetDepositorAllowlistId: FunctionConfig = {
  signature: "function setDepositorAllowlistId(uint64 _depositorAllowlistId)",

  Description() {
    return <>Set Deposit Allowlist</>;
  },

  Label() {
    return <>Set Deposit Allowlist</>;
  },
};
