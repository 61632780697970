import { Environment, toAddress } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Form, FormErrorMessage, TokenInput, useForm } from "@enzymefinance/hook-form";
import { Asset, Tools } from "@enzymefinance/sdk";
import { Alert, Button, Card, DateDisplay, DurationDisplay, Modal } from "@enzymefinance/ui";
import { refine, safeParse, tokenInput } from "@enzymefinance/validation";
import { captureException } from "@sentry/react";
import { useSigner } from "components/connection/Connection";
import { useAssetPrices } from "components/providers/AssetPricesProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import type { StartSdkFunction } from "components/transactions/TransactionModal";
import type { MachineState } from "components/transactions/TransactionModalMachine";
import type { VaultDetailsFragment } from "queries/core";
import { useCallback } from "react";
import { decodeTransactionData } from "utils/functions";
import { useAllowance } from "utils/hooks/useAllowance";
import { useApproveTransactions } from "utils/hooks/useApproveTransactions";
import type { SharedDepositQueueDepositInfo } from "utils/hooks/useSingleAssetDepositQueueInfo";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { isAddressEqual } from "viem";
import { z } from "zod";
import { VaultFlexibleLoanAgreementModal } from "./VaultFlexibleLoanAgreementModal";
import { VaultDepositFormCommonPart } from "./components/VaultDepositFormCommonPart";
import { VaultDepositFormSubmitButton } from "./components/VaultDepositFormSubmitButton";

const schema = z
  .object({
    depositAmount: tokenInput,
    minDeposit: z.bigint(),
    termsAccepted: z
      .boolean()
      .transform(refine((value) => value, { message: "You must accept the Terms & Conditions." })),
    tokenBalance: z.bigint(),
    isFlexibleLoanVault: z.boolean(),
    flexLoansAccepted: z.boolean(),
  })
  .transform(
    refine((value) => value.depositAmount.value <= value.tokenBalance, {
      message: "The deposit amount exceeds your balance.",
      path: ["depositAmount"],
    }),
  )
  .transform(
    refine((value) => value.minDeposit === 0n || value.depositAmount.value >= value.minDeposit, {
      message: "The deposit amount is below the minimum deposit amount of the vault.",
      path: ["depositAmount"],
    }),
  )
  .transform(
    refine((value) => (value.isFlexibleLoanVault ? value.flexLoansAccepted : true), {
      message: "You must accept the Flexible loans terms.",
      path: ["flexLoansAccepted"],
    }),
  );

interface VaultDepositDepositQueueFormProps {
  startTransaction: StartSdkFunction;
  close: () => void;
  depositQueueDepositInfo: SharedDepositQueueDepositInfo;
  isFlexibleLoanVault: boolean;
  isFlexibleLoanModalOpen: boolean;
  openFlexibleLoanModal: () => void;
  state: MachineState;
  vault: VaultDetailsFragment;
  displayAllowedExternalPositionTypesWarning: boolean;
  vaultEntranceFeeRate: number | undefined;
  vaultHasUnknownFeeOrPolicy: boolean;
}

export function VaultDepositDepositQueueForm({
  startTransaction,
  close,
  vault,
  depositQueueDepositInfo,
  state,
  isFlexibleLoanVault,
  isFlexibleLoanModalOpen,
  openFlexibleLoanModal,
  displayAllowedExternalPositionTypesWarning,
  vaultEntranceFeeRate,
  vaultHasUnknownFeeOrPolicy,
}: VaultDepositDepositQueueFormProps) {
  const [signerAddress] = useSigner();
  const { loading: assetPricesLoading } = useAssetPrices();
  const { client, environment } = useNetwork();

  const depositedAssetAmount = depositQueueDepositInfo.depositAssetAmount;

  const depositAsset = depositQueueDepositInfo.depositAssetAddress
    ? environment.getAsset(toAddress(depositQueueDepositInfo.depositAssetAddress))
    : undefined;

  if (depositAsset === undefined) {
    return <Modal.Body>Unknown Deposit Asset for Deposit Queue</Modal.Body>;
  }

  const allowanceQuery = useAllowance(client, {
    account: signerAddress,
    spender: depositQueueDepositInfo.depositQueueAddress,
    token: depositAsset?.id,
  });

  const form = useForm({
    defaultValues: {
      depositAmount: {
        token: depositAsset,
        value: undefined,
      },
      minDeposit: depositQueueDepositInfo.minDepositAmount,
      termsAccepted: false,
      tokenBalance: undefined,
      flexLoansAccepted: false,
      isFlexibleLoanVault,
    },
    mode: "onChange",
    onSubmit(values, helpers) {
      try {
        if (!signerAddress) {
          return helpers.setError("form", {
            message: "Wallet not connected. You must connect your wallet to perform this action.",
          });
        }

        if (allowanceQuery.data === undefined) {
          return helpers.setError("form", {
            message: "Error fetching the allowance",
          });
        }

        if (allowanceQuery.data < values.depositAmount.value) {
          const fn = Asset.approve({
            asset: depositAsset.id,
            amount: values.depositAmount.value,
            spender: depositQueueDepositInfo.depositQueueAddress,
          });
          startTransaction(fn, signerAddress);
        } else {
          const fn = Tools.SingleAssetDepositQueue.requestDeposit({
            depositQueue: depositQueueDepositInfo.depositQueueAddress,
            assetAmount: values.depositAmount.value,
          });
          startTransaction(fn, signerAddress);
          close();
        }
      } catch (error) {
        captureException(error);

        return helpers.setError("form", {
          message: "Something went wrong.",
          type: "report",
        });
      }
    },
    schema,
  });

  useTokenBalance(
    client,
    {
      account: signerAddress,
      token: depositAsset.id,
    },
    {
      onSuccess: (data) => {
        form.setValue("tokenBalance", data);
      },
    },
  );

  const cancelRequestDeposit = (requestId: bigint) =>
    useCallback(() => {
      if (!signerAddress) {
        return;
      }

      const fn = Tools.SingleAssetDepositQueue.cancelRequest({
        depositQueue: depositQueueDepositInfo.depositQueueAddress,
        requestId,
      });

      startTransaction(fn, signerAddress);
      close();
    }, [depositQueueDepositInfo.depositAssetAddress, signerAddress, startTransaction]);

  const isFormDisabled = assetPricesLoading;

  const [depositAmount, termsAccepted, tokenBalance] = form.watch(["depositAmount", "termsAccepted", "tokenBalance"]);

  const parsedDepositAmount = safeParse(tokenInput, depositAmount);

  const sufficientAllowance = parsedDepositAmount && (allowanceQuery.data ?? 0n) >= parsedDepositAmount.value;

  const approveTransactions = useApproveTransactions(depositQueueDepositInfo.depositQueueAddress);
  const isValid = !!(
    form.formState.isValid &&
    !form.formState.isSubmitting &&
    !form.formState.isValidating &&
    allowanceQuery.data
  );

  const isSubmittingApprove = approveTransactions.isSubmitting;
  const isPendingApprove = approveTransactions.isPending;
  const txData = state.context.txData?.data;
  const isRequestDeposit =
    txData !== undefined ? decodeTransactionData(txData)?.fragment.name === "requestDeposit" : false;
  const isSubmittingRequestDeposit = form.formState.isSubmitting && isRequestDeposit;

  const isShutdown = depositQueueDepositInfo.shutdown === true;

  const isEthereumUsdt =
    Environment.isDeploymentEthereum(environment) && isAddressEqual(depositAsset.id, environment.namedTokens.usdt.id);

  const isUsdtLocked = isEthereumUsdt && !(allowanceQuery.data === 0n) && !sufficientAllowance;

  const isNextButtonDisabled =
    !termsAccepted || form.formState.errors.depositAmount !== undefined || !parsedDepositAmount?.value;

  return (
    <Form form={form}>
      {isFlexibleLoanModalOpen ? (
        <VaultFlexibleLoanAgreementModal
          submitButton={
            <VaultDepositFormSubmitButton
              asset={depositAsset}
              isPendingApprove={isPendingApprove}
              isSubmittingApprove={isSubmittingApprove}
              isSubmittingBuyShares={isSubmittingRequestDeposit}
              isUSDTLocked={isUsdtLocked}
              isValid={isValid}
              sufficientAllowance={sufficientAllowance}
            />
          }
        />
      ) : (
        <>
          <Modal.Body className="space-y-4 ">
            {isShutdown ? (
              <p>The deposit queue is shut down. Please cancel your pending requests.</p>
            ) : (
              <p>
                To deposit your investment, please submit a request. It will be reviewed and executed alongside other
                deposit requests on a FIFO (First In, First Out) basis.
              </p>
            )}
            <Card appearance="secondary">
              <Card.Content className="space-y-2 divide-y divide-gray-300 dark:divide-gray-700">
                <div className="flex flex-row items-baseline justify-between">
                  <span className="text-base-content text-md font-medium">Your Current Deposited Balance</span>
                  <div className="text-base-content text-md font-medium">
                    <BigIntDisplay
                      numberFormat={{ currency: depositAsset.symbol, maximumFractionDigits: 8 }}
                      decimals={depositAsset.decimals}
                      value={depositedAssetAmount}
                    />
                  </div>
                </div>
              </Card.Content>
            </Card>
            {depositedAssetAmount > 0n
              ? depositQueueDepositInfo.requests.map((request) => (
                  <Alert
                    appearance="info"
                    key={request.id}
                    title={
                      <span>
                        Your request to deposit{" "}
                        <BigIntDisplay
                          numberFormat={{ currency: depositAsset.symbol, maximumFractionDigits: 8 }}
                          decimals={depositAsset.decimals}
                          value={request.depositAssetAmount}
                        />{" "}
                        is pending
                      </span>
                    }
                  >
                    {request.isCancelable ? (
                      <div className="space-y-3">
                        <Button appearance="destructive" onClick={cancelRequestDeposit(request.requestId)}>
                          Cancel request
                        </Button>
                      </div>
                    ) : (
                      <div>
                        Cancelable at <DateDisplay value={request.cancelableAt} appearance="simple" />
                      </div>
                    )}
                  </Alert>
                ))
              : null}

            {isShutdown ? null : (
              <TokenInput
                balance={tokenBalance}
                label="Deposit Amount"
                name="depositAmount"
                tokens={[depositAsset]}
                disabled={isFormDisabled}
              />
            )}
            <div>
              You will be able to cancel request in <DurationDisplay seconds={depositQueueDepositInfo.minRequestTime} />
            </div>
            <VaultDepositFormCommonPart
              vault={vault}
              minDeposit={depositQueueDepositInfo.minDepositAmount}
              vaultEntranceFeeRate={vaultEntranceFeeRate}
              displaySlippageBelowThresholdWarning={false}
              displayAllowedExternalPositionTypesWarning={displayAllowedExternalPositionTypesWarning}
              vaultHasUnknownFeeOrPolicy={vaultHasUnknownFeeOrPolicy}
              displayMaximumSlippage={false}
            />

            <FormErrorMessage />
          </Modal.Body>
          {isShutdown ? null : (
            <Modal.Actions>
              {isFlexibleLoanVault ? (
                <Button appearance="primary" onClick={openFlexibleLoanModal} disabled={isNextButtonDisabled}>
                  Next
                </Button>
              ) : (
                <VaultDepositFormSubmitButton
                  asset={depositAsset}
                  isPendingApprove={isPendingApprove}
                  isSubmittingApprove={isSubmittingApprove}
                  isSubmittingBuyShares={isSubmittingRequestDeposit}
                  isUSDTLocked={isUsdtLocked}
                  isValid={isValid}
                  sufficientAllowance={sufficientAllowance}
                />
              )}
            </Modal.Actions>
          )}
        </>
      )}
    </Form>
  );
}
