import type { Address } from "@enzymefinance/environment";
import { ArrowDownTrayIcon } from "@enzymefinance/icons/solid";
import { Button, useBoolean } from "@enzymefinance/ui";
import type { VaultDetailsFragment } from "queries/core";

import { VaultDepositModal } from "../deposit/VaultDepositModal";

interface VaultDepositButtonProps {
  vault: VaultDetailsFragment;
  signerAddress: Address;
}

export function VaultDepositButton({ vault, signerAddress }: VaultDepositButtonProps) {
  const [isOpen, open, close] = useBoolean();

  return (
    <>
      <Button appearance="primary" leadingIcon={ArrowDownTrayIcon} size="xl" onClick={open}>
        Deposit
      </Button>

      <VaultDepositModal close={close} isOpen={isOpen} vault={vault} signerAddress={signerAddress} />
    </>
  );
}
