import type { Address } from "@enzymefinance/environment";
import { ArrowUpTrayIcon } from "@enzymefinance/icons/solid";
import { Button, useBoolean } from "@enzymefinance/ui";
import type { VaultDetailsFragment } from "queries/core";
import type { SharedSharesWrapperRedemptionInfo } from "utils/hooks/useSharesWrapperRedemptionInfo";
import type { SharedRedemptionQueueRedemptionInfo } from "../../../utils/hooks/useSingleAssetRedemptionQueueInfo";
import { VaultRedeemSharesModal } from "../redemption/VaultRedeemSharesModal";

interface VaultRedeemButtonProps {
  comptrollerProxy: string;
  vault: VaultDetailsFragment;
  signerAddress: Address;
  sharesWrapperRedemptionInfo: SharedSharesWrapperRedemptionInfo | null;
  redemptionQueueRedemptionInfo: SharedRedemptionQueueRedemptionInfo | null;
  loading: boolean;
}

export function VaultRedeemButton({
  comptrollerProxy,
  vault,
  signerAddress,
  sharesWrapperRedemptionInfo,
  redemptionQueueRedemptionInfo,
  loading,
}: VaultRedeemButtonProps) {
  const [isOpen, open, close] = useBoolean();

  return (
    <>
      <Button appearance="secondary" leadingIcon={ArrowUpTrayIcon} size="xl" onClick={open}>
        Redeem
      </Button>
      <VaultRedeemSharesModal
        close={close}
        isOpen={isOpen}
        comptrollerProxy={comptrollerProxy}
        vault={vault}
        signerAddress={signerAddress}
        sharesWrapperRedemptionInfo={sharesWrapperRedemptionInfo}
        redemptionQueueRedemptionInfo={redemptionQueueRedemptionInfo}
        loading={loading}
      />
    </>
  );
}
