import type { FunctionConfig } from "utils/functionsTypes";

export const depositQueueSetMinDepositAssetAmount: FunctionConfig = {
  signature: "function setMinDepositAssetAmount(uint128 _minDepositAssetAmount)",

  Description() {
    return <>Set min deposit amount</>;
  },

  Label() {
    return <>Set Min Deposit Amount</>;
  },
};
