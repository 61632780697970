import type { FunctionConfig } from "utils/functionsTypes";

export const queueShutdown: FunctionConfig = {
  signature: "function shutdown()",

  Description() {
    return <>Shutdown Queue.</>;
  },

  Label() {
    return <>Shutdown Queue</>;
  },
};
