export function getDepositQueue<TDepositQueue extends { id: string }>({
  singleAssetDepositQueues,
  allowedDepositRecipientsPolicy,
}: {
  singleAssetDepositQueues: TDepositQueue[];
  allowedDepositRecipientsPolicy: {
    enabled: boolean;
    addressLists: {
      items: string[];
    }[];
  };
}) {
  if (!allowedDepositRecipientsPolicy.enabled) {
    return null;
  }

  const depositQueueInPolicy = singleAssetDepositQueues.filter((singleAssetDepositQueue) =>
    allowedDepositRecipientsPolicy.addressLists.find((addressList) =>
      addressList.items.includes(singleAssetDepositQueue.id),
    ),
  );

  return depositQueueInPolicy[0] ?? null;
}
