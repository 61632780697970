import { AssetType } from "@enzymefinance/environment";
import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { isAddressEqual } from "viem";
import type { IntegrationHandler } from "./types";

export const buyPrincipalToken: IntegrationHandler<Integrations.PendleV2.BuyPrincipleTokenWithAdapterArgs> = {
  Description({ args: { depositTokenAddress, depositAmount, market } }) {
    const { environment } = useGlobals();

    const depositAsset = environment.getAsset(depositTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={depositAmount}
            numberFormat={{ currency: depositAsset.symbol, maximumFractionDigits: 8 }}
            decimals={depositAsset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Deposit Fixed Yield</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.PendleV2.buyPrincipleTokenWithAdapterDecode(encodedCallArgs),
};

export const sellPrincipalToken: IntegrationHandler<Integrations.PendleV2.SellPrincipleTokenWithAdapterArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.withdrawalTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.withdrawalAmount}
            numberFormat={{ currency: asset.symbol, maximumFractionDigits: 8 }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Withdraw Fixed Yield</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) =>
    Integrations.PendleV2.sellPrincipleTokenWithAdapterDecode(encodedCallArgs),
};

export const addLiquidity: IntegrationHandler<Integrations.PendleV2.AddLiquidityWithAdapterArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.depositTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.depositAmount}
            numberFormat={{ currency: asset.symbol, maximumFractionDigits: 8 }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Deposit Liquidity</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.PendleV2.addLiquidityWithAdapterDecode(encodedCallArgs),
};

export const removeLiquidityToUnderlying: IntegrationHandler<Integrations.PendleV2.RemoveLiquidityToUnderlyingWithAdapterArgs> =
  {
    Description({ args }) {
      const { environment } = useGlobals();

      const lpToken = environment.getAsset(args.market);
      const withdrawalToken = environment.getAsset(args.withdrawalTokenAddress);

      return (
        <div className="space-y-2 pt-4">
          <div className="flex flex-row justify-between">
            <span>LP amount withdrawn</span>
            <BigIntDisplay
              value={args.withdrawalAmount}
              numberFormat={{ currency: lpToken.symbol, maximumFractionDigits: 8 }}
              decimals={lpToken.decimals}
            />
          </div>
          <div className="flex flex-row justify-between">
            <span>Min received</span>
            <BigIntDisplay
              value={args.withdrawalAmount}
              numberFormat={{ currency: withdrawalToken.symbol, maximumFractionDigits: 8 }}
              decimals={withdrawalToken.decimals}
            />
          </div>
          <div className="flex flex-row justify-between">
            <span>Market</span> <Address address={args.market} trimmed={true} />
          </div>
        </div>
      );
    },
    Label() {
      return <>Withdraw Liquidity to Underlying</>;
    },
    decodeIntegrationArgs: (encodedCallArgs) =>
      Integrations.PendleV2.removeLiquidityToUnderlyingWithAdapterDecode(encodedCallArgs),
  };

export const removeLiquidityToPtAndUnderlying: IntegrationHandler<Integrations.PendleV2.RemoveLiquidityToPtAndUnderlyingWithAdapterArgs> =
  {
    Description({ args }) {
      const { environment } = useGlobals();

      const lpToken = environment.getAsset(args.market);
      const withdrawalToken = environment.getAsset(args.withdrawalTokenAddress);

      const ptAssets = environment.getAssets({ types: [AssetType.PENDLE_V2_PT] });

      const ptAsset = ptAssets.find((asset) => asset.markets.some((market) => isAddressEqual(market, args.market)));
      Assertion.invariant(ptAsset !== undefined, `PT asset not found for market ${args.market}`);

      return (
        <div className="space-y-4 divide-y divide-base-400 pt-4">
          <div className="space-y-4">
            <div className="flex flex-row justify-between">
              <span>LP amount withdrawn</span>
              <BigIntDisplay
                value={args.lpAmount}
                numberFormat={{ currency: lpToken.symbol, maximumFractionDigits: 8 }}
                decimals={lpToken.decimals}
              />
            </div>
            <div className="flex flex-row justify-between">
              <span>Market</span> <Address address={args.market} trimmed={true} />
            </div>
          </div>

          <div className="space-y-4 pt-4">
            <span>You will receive min:</span>
            <div className="flex flex-row justify-between">
              <span>Underlying Amount</span>
              <BigIntDisplay
                value={args.minWithdrawalTokenAmount}
                numberFormat={{ currency: withdrawalToken.symbol, maximumFractionDigits: 8 }}
                decimals={withdrawalToken.decimals}
              />
            </div>
            <div className="flex flex-row justify-between">
              <span>PT Amount</span>
              <BigIntDisplay
                value={args.minPtAmount}
                numberFormat={{ currency: ptAsset.symbol, maximumFractionDigits: 8 }}
                decimals={ptAsset.decimals}
              />
            </div>
          </div>
        </div>
      );
    },
    Label() {
      return <>Withdraw Liquidity to PT and Underlying</>;
    },
    decodeIntegrationArgs: (encodedCallArgs) =>
      Integrations.PendleV2.removeLiquidityToPtAndUnderlyingWithAdapterDecode(encodedCallArgs),
  };
