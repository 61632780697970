import { toAddress } from "@enzymefinance/environment";
import type {
  ExternalPosition,
  ExternalPositionDefinition,
  ExternalPositionRecord,
  ReleaseContracts,
} from "@enzymefinance/utils";
import { ExternalPositionType, isTruthy } from "@enzymefinance/utils";
import { isAddressEqual, zeroAddress } from "viem";

interface AddressesToExternalPositionsArgs {
  addresses: string[];
  contracts: ReleaseContracts;
}

export function addressesToExternalPositions({
  addresses,
  contracts,
}: AddressesToExternalPositionsArgs): ExternalPosition[] {
  const externalPositionDefinitions = addresses
    .map((address) =>
      Object.values(externalPositions).find((externalPosition) =>
        isAddressEqual(toAddress(address), externalPosition.address(contracts)),
      ),
    )
    .filter(isTruthy);

  return externalPositionDefinitions.map((externalPositionDefinition) => ({
    id: externalPositionDefinition.address(contracts),
    name: externalPositionDefinition.label,
    url: externalPositionDefinition.icon,
  }));
}

export const externalPositions: ExternalPositionRecord = {
  [ExternalPositionType.AAVE_DEBT]: {
    address: (contracts) => contracts.AaveDebtPositionLib,
    icon: getExternalPositionIconUrl("aave-debt"),
    label: "Aave V2 Borrow",
    type: ExternalPositionType.AAVE_DEBT,
  },
  [ExternalPositionType.AAVE_V3_DEBT]: {
    address: (contracts) => contracts.AaveV3DebtPositionLib,
    icon: getExternalPositionIconUrl("aave-debt"),
    label: "Aave V3 Borrow",
    type: ExternalPositionType.AAVE_V3_DEBT,
  },
  [ExternalPositionType.ALICE]: {
    address: (contracts) => contracts.AlicePositionLib,
    icon: getExternalPositionIconUrl("lmax"),
    label: "LMAX",
    type: ExternalPositionType.ALICE,
  },
  [ExternalPositionType.COMPOUND_DEBT]: {
    address: (contracts) => contracts.CompoundDebtPositionLib,
    icon: getExternalPositionIconUrl("compound-debt"),
    label: "Compound Borrow",
    type: ExternalPositionType.COMPOUND_DEBT,
  },
  [ExternalPositionType.CONVEX_VOTING]: {
    address: (contracts) => contracts.ConvexVotingPositionLib,
    icon: getExternalPositionIconUrl("convex-voting"),
    label: "Convex Vote",
    type: ExternalPositionType.CONVEX_VOTING,
  },
  [ExternalPositionType.ARBITRARY_LOAN]: {
    address: (contracts) => contracts.ArbitraryLoanPositionLib,
    icon: getExternalPositionIconUrl("arbitrary-loan"),
    label: "Flexible Loan",
    type: ExternalPositionType.ARBITRARY_LOAN,
  },
  [ExternalPositionType.KILN_STAKING]: {
    address: (contracts) => contracts.KilnStakingPositionLib,
    icon: getExternalPositionIconUrl("kiln-staking"),
    label: "Kiln Staking",
    type: ExternalPositionType.KILN_STAKING,
  },
  [ExternalPositionType.LIQUITY_DEBT]: {
    address: () => zeroAddress,
    icon: getExternalPositionIconUrl("liquity-debt"),
    label: "Liquity Borrow",
    type: ExternalPositionType.LIQUITY_DEBT,
  },
  [ExternalPositionType.LIDO_WITHDRAWALS]: {
    address: (contracts) => contracts.LidoWithdrawalsPositionLib,
    icon: getExternalPositionIconUrl("lido-withdrawals"),
    label: "Lido Withdrawals",
    type: ExternalPositionType.LIDO_WITHDRAWALS,
  },
  [ExternalPositionType.MAPLE_LIQUIDITY]: {
    address: (contracts) => contracts.MapleLiquidityPositionLib,
    icon: getExternalPositionIconUrl("maple-liquidity"),
    label: "Maple Lend",
    type: ExternalPositionType.MAPLE_LIQUIDITY,
  },
  [ExternalPositionType.MORPHO_BLUE]: {
    address: (contracts) => contracts.MorphoBluePositionLib,
    icon: getExternalPositionIconUrl("morpho"),
    label: "Morpho",
    type: ExternalPositionType.MORPHO_BLUE,
  },
  [ExternalPositionType.THEGRAPH_DELEGATION]: {
    address: (contracts) => contracts.TheGraphDelegationPositionLib,
    icon: getExternalPositionIconUrl("thegraph-delegation"),
    label: "The Graph Delegation",
    type: ExternalPositionType.THEGRAPH_DELEGATION,
  },
  [ExternalPositionType.UNISWAP_V3_LIQUIDITY]: {
    address: (contracts) => contracts.UniswapV3LiquidityPositionLib,
    icon: getExternalPositionIconUrl("uniswap-v3-liquidity"),
    label: "Uniswap V3 Provide Liquidity",
    type: ExternalPositionType.UNISWAP_V3_LIQUIDITY,
  },
  [ExternalPositionType.STADER_WITHDRAWALS]: {
    address: (contracts) => contracts.StaderWithdrawalsPositionLib,
    icon: getExternalPositionIconUrl("stader-withdrawals"),
    label: "Stader Withdrawals",
    type: ExternalPositionType.STADER_WITHDRAWALS,
  },
  [ExternalPositionType.STAKEWISE_V3]: {
    address: (contracts) => contracts.StakeWiseV3StakingPositionLib,
    icon: getExternalPositionIconUrl("stakewise"),
    label: "Stakewise V3 Staking",
    type: ExternalPositionType.STAKEWISE_V3,
  },
  [ExternalPositionType.PENDLE_V2]: {
    address: (contracts) => contracts.PendleV2PositionLib,
    icon: getExternalPositionIconUrl("pendle"),
    label: "Pendle V2",
    type: ExternalPositionType.PENDLE_V2,
  },
  [ExternalPositionType.GMX_V2_LEVERAGE_TRADING]: {
    address: (contracts) => contracts.GMXV2LeverageTradingPositionLib,
    icon: getExternalPositionIconUrl("gmx"),
    label: "GMX V2 Leverage Trading",
    type: ExternalPositionType.GMX_V2_LEVERAGE_TRADING,
  },
  [ExternalPositionType.UNKNOWN]: getUnknownExternalPosition(ExternalPositionType.UNKNOWN),
  [ExternalPositionType.NOTIONAL_V2]: getUnknownExternalPosition(ExternalPositionType.NOTIONAL_V2),
  [ExternalPositionType.ZERO_LEND_LRT_BTC_AAVE_V3_DEBT]: {
    address: (contracts) => contracts.ZeroLendLRTBTCAaveV3DebtPositionLib,
    icon: getExternalPositionIconUrl("zero-lend-debt"),
    label: "Zero Lend LRT BTC Borrow",
    type: ExternalPositionType.ZERO_LEND_LRT_BTC_AAVE_V3_DEBT,
  },
  [ExternalPositionType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3_DEBT]: {
    address: (contracts) => contracts.ZeroLendRWAStablecoinsAaveV3DebtPositionLib,
    icon: getExternalPositionIconUrl("zero-lend-debt"),
    label: "Zero Lend RWA Stablecoins Borrow",
    type: ExternalPositionType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3_DEBT,
  },
} as const;

/**
 * Represents an ExternalPositionDefinition within the context of a deployment, therefore with the address resolved
 */
export interface ResolvedExternalPositionDefinition<Type extends ExternalPositionType = ExternalPositionType>
  extends Omit<ExternalPositionDefinition<Type>, "address"> {
  address: string;
}

export type ResolvedExternalPositionDefinitionByAddress<Type extends ExternalPositionType = ExternalPositionType> =
  Record<string, ResolvedExternalPositionDefinition<Type>>;

export function getExternalPositionsByAddress(
  contracts: ReleaseContracts,
): ResolvedExternalPositionDefinitionByAddress {
  return Object.values(externalPositions).reduce<ResolvedExternalPositionDefinitionByAddress>(
    (acc, externalPosition) => {
      const address = externalPosition.address(contracts).toLowerCase();

      if (address && !isAddressEqual(toAddress(address), zeroAddress)) {
        const resolvedExternalPosition: ResolvedExternalPositionDefinition = {
          ...externalPosition,
          address,
        };

        acc[address] = resolvedExternalPosition;
      }

      return acc;
    },
    {},
  );
}

export function getExternalPositionIconUrl(id: string) {
  return `/external-positions/${id}.png`;
}

function getUnknownExternalPosition<T>(type: T) {
  return {
    address: () => toAddress(zeroAddress),
    icon: getExternalPositionIconUrl("unknown"),
    label: "Unknown External Position",
    type: type,
  };
}
