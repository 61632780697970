import { AdapterType, AssetType } from "@enzymefinance/environment";
import { BigIntDisplay, TokenIcon } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Card } from "@enzymefinance/ui";
import type { AaveV3LikeAdapterType } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { IntegrationHandler } from "./types";

const adapterTypeConfig = {
  [AdapterType.AAVE_V3]: {
    assetType: AssetType.AAVE_V3,
    name: "Aave V3",
  },
  [AdapterType.ZERO_LEND_LRT_BTC_AAVE_V3]: {
    assetType: AssetType.ZERO_LEND_LRT_BTC_AAVE_V3,
    name: "Zero Lend LRT BTC",
  },
  [AdapterType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3]: {
    assetType: AssetType.ZERO_LEND_RWA_STABLECOINS_AAVE_V3,
    name: "Zero Lend RWA Stablecoins",
  },
} satisfies Record<AaveV3LikeAdapterType, { assetType: AssetType; name: string }>;

export const aaveV3Lend: (adapterType: AaveV3LikeAdapterType) => IntegrationHandler<Integrations.AaveV3.LendArgs> = (
  adapterType,
) => ({
  Description({ args: { aToken: aTokenAddress, depositAmount } }) {
    const { environment } = useGlobals();

    const config = adapterTypeConfig[adapterType];

    const aToken = environment.getAssetAs(aTokenAddress, config.assetType);
    const underlyingToken = environment.getAsset(aToken.underlying);

    return (
      <div className="space-y-4">
        <span>
          Supply on {config.name} (converting{" "}
          <BigIntDisplay
            value={depositAmount}
            decimals={underlyingToken.decimals}
            numberFormat={{ currency: underlyingToken.symbol }}
          />{" "}
          into {aToken.symbol})
        </span>
        <Card appearance="secondary">
          <Card.Content className="flex justify-between items-center">
            <span>{underlyingToken.symbol}</span>
            <span className="flex space-x-2">
              <BigIntDisplay
                value={depositAmount}
                decimals={underlyingToken.decimals}
                numberFormat={{ maximumFractionDigits: 8 }}
              />
              <TokenIcon asset={underlyingToken} size={5} />
            </span>
          </Card.Content>
        </Card>
      </div>
    );
  },
  Label() {
    return <>Supply</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.AaveV3.lendDecode(encodedCallArgs),
});

export const aaveV3Redeem: (adapterType: AaveV3LikeAdapterType) => IntegrationHandler<Integrations.AaveV3.RedeemArgs> =
  (adapterType) => ({
    Description({ args: { aToken: aTokenAddress, redeemAmount } }) {
      const { environment } = useGlobals();

      const config = adapterTypeConfig[adapterType];

      const aToken = environment.getAssetAs(aTokenAddress, config.assetType);
      const underlyingToken = environment.getAsset(aToken.underlying);

      return (
        <div className="space-y-4">
          <span>
            Redeeming on {config.name} (converting{" "}
            <BigIntDisplay
              value={redeemAmount}
              decimals={aToken.decimals}
              numberFormat={{ currency: aToken.symbol, maximumFractionDigits: 8 }}
            />{" "}
            into {underlyingToken.symbol})
          </span>
          <Card appearance="secondary">
            <Card.Content className="flex justify-between items-center">
              <span>{aToken.symbol}</span>
              <span className="flex space-x-2">
                <BigIntDisplay
                  value={redeemAmount}
                  decimals={aToken.decimals}
                  numberFormat={{ maximumFractionDigits: 8 }}
                />
                <TokenIcon asset={underlyingToken} size={5} />
              </span>
            </Card.Content>
          </Card>
        </div>
      );
    },
    Label() {
      return <>Redeem</>;
    },
    decodeIntegrationArgs: (encodedCallArgs) => Integrations.AaveV3.redeemDecode(encodedCallArgs),
  });
